import moment from 'moment-timezone'

export const payloadMixin = {
  data: () => ({
    timeZone: 'America/Cancun',
  }),
  methods: {
    /**
     * Asigna los valores de las propiedades del primer nivel.
     * @param {Object} source - El objeto fuente desde donde se copian las propiedades.
     * @param {Object} target - El objeto objetivo donde se copian las propiedades.
     */
    assignFirstLevelValues(source, target) {
      const keys = [
        '_id',
        'cliente',
        'nombre',
        'apellido',
        'codigo',
        'celular',
        'genero',
        'nota',
        'origen',
        'estatus',
        'activo',
      ]
      keys.forEach((key) => {
        target[key] = source[key]
      })
      if (source.correo) {
        target.correo = source.correo.toLowerCase()
      }
      if (source.codigo) {
        target.codigo = source.codigo.replaceAll('+', '')
      }
    },

    /**
     * Asigna los valores de las propiedades del segundo nivel.
     * @param {Object} source - El objeto fuente desde donde se copian las propiedades.
     * @param {Object} target - El objeto objetivo donde se copian las propiedades.
     */
    assignSecondLevelValues(source, target) {
      const servicios = source.servicios[0]
      const clave = servicios.clave
      const pasajeros = servicios.pasajeros.toString().split('.')
      this.setTipoTraslado(servicios.tipo_traslado) // Actualiza el store

      target.servicios[0].tipo_traslado = servicios.tipo_traslado // sencillo - redondo
      // target.servicios[0].aeropuerto = servicios.aeropuerto
      target.clave_traslado = clave
      target.aeropuerto = servicios.aeropuerto
      target.tipo_vehiculo = servicios.tipo_vehiculo
      target.adultos = parseInt(pasajeros[0])
      target.menores = pasajeros[1] !== undefined ? parseInt(pasajeros[1]) : 0
    },

    /**
     * Asigna un solo traslado al objeto editedItem.
     * @param {Object} traslado - El traslado a asignar.
     * @param {String} direction - La dirección del traslado ('Departure' o 'Arrival').
     * @param {String} type - El tipo del traslado ('ida' o 'vuelta').
     *
     * @example
     * assignTraslado(
     *   { fecha: '2023-07-23T00:00:00Z', hora: '09:00', pickup: '2023-07-23T07:00:00Z' },
     *   'Departure',
     *   'ida'
     * )
     */
    assignTraslado(traslado, direction, type) {
      this.editedItem[`fecha_${type}`] = this.formatDate(traslado.fecha, 'YYYY-MM-DD')
      this.editedItem[`hora_${type}`] = this.convertTo24HourFormat(traslado.hora)
      this.editedItem[`pickup_${type}`] = this.formatDate(traslado.pickup, 'HH:mm')
      this[`setPickup${type.charAt(0).toUpperCase() + type.slice(1)}`](traslado.pickup)

      this.obtenerVuelosChafa(null, null, direction, type)
      this.editedItem[`aerolinea_${type}`] = traslado.aerolinea
      this.onChangeAirline(traslado.aerolinea, type)
      this.editedItem[`vuelo_${type}`] = traslado.vuelo

      if ('amenidades' in traslado && traslado.amenidades.length > 0) {
        const amenidades = this.prepareAmenidades(traslado.amenidades)
        this[`initialCart${type.charAt(0).toUpperCase() + type.slice(1)}`] = amenidades
        this.editedItem[`productos${type}`] = amenidades
      }

      if ('extras' in traslado && traslado.extras.length > 0) {
        const extras = this.prepareExtras(traslado.extras)
        this[`initialExtras${type.charAt(0).toUpperCase() + type.slice(1)}`] = extras
        this.editedItem[`extras${type}`] = extras
      }
    },

    /**
     * Asigna los traslados contenidos en servicios al objeto editedItem.
     * @param {Object} servicios - El objeto que contiene los traslados a asignar.
     * @param {String} direction - La dirección del traslado ('Departure' o 'Arrival').
     *
     * @example
     * assignTraslados({
     *   clave: 'apto-hotel-apto',
     *   traslados: [
     *     { fecha: '2023-07-23T00:00:00Z', hora: '09:00', pickup: '2023-07-23T07:00:00Z' },
     *     { fecha: '2023-07-30T00:00:00Z', hora: '18:00', pickup: '2023-07-30T16:00:00Z' }
     *   ]
     * }, 'Departure')
     */
    assignTraslados(servicios, direction) {
      if ('traslados' in servicios && servicios.traslados.length > 0) {
        this.initialCartTraslados = servicios.traslados

        this.assignTraslado(servicios.traslados[0], direction, 'ida')
        if (servicios.traslados[1]) {
          this.assignTraslado(
            servicios.traslados[1],
            servicios.clave.includes('apto') ? this.switchDirection(direction) : direction,
            'vuelta'
          )
        }
      }
    },

    /**
     * Convierte una hora en formato de 24 horas a formato de 12 horas (AM/PM).
     *
     * @param {string} timeString - La hora en formato de cadena. Puede estar en formato de 12 o 24 horas.
     * @returns {string} La hora convertida en formato de 12 horas. Si la hora ya estaba en este formato, se devuelve tal cual.
     *
     * @example
     * // Si la hora está en formato de 24 horas
     * convertTo12HourFormat('22:50'); // retorna '10:50 PM'
     *
     * // Si la hora está en formato de 12 horas
     * convertTo12HourFormat('10:50 PM'); // retorna '10:50 PM'
     */
    convertTo12HourFormat(timeString) {
      const format12h = 'hh:mm A'
      const format24h = 'HH:mm'

      return moment(timeString, format24h, true).isValid()
        ? moment(timeString, format24h).format(format12h)
        : timeString
    },

    /**
     * Convierte una hora en formato de 12 horas (AM/PM) a formato de 24 horas.
     *
     * @param {string} timeString - La hora en formato de cadena. Puede estar en formato de 12 o 24 horas.
     * @returns {string} La hora convertida en formato de 24 horas. Si la hora ya estaba en este formato, se devuelve tal cual.
     *
     * @example
     * // Si la hora está en formato de 12 horas
     * convertTo24HourFormat('10:50 PM'); // retorna '22:50'
     *
     * // Si la hora está en formato de 24 horas
     * convertTo24HourFormat('22:50'); // retorna '22:50'
     *
     * @method convertTo24HourFormat
     */
    convertTo24HourFormat(timeString) {
      const format12h = 'hh:mm A'
      const format24h = 'HH:mm'
      /* let formattedTime = ''

      if (moment(timeString, format12h, true).isValid()) {
        formattedTime = moment(timeString, format12h).format(format24h)
      } else {
        formattedTime = timeString
      }

      return formattedTime */
      return moment(timeString, format12h, true).isValid()
        ? moment(timeString, format12h).format(format24h)
        : timeString
    },

    /**
     * Formatea una fecha en el formato dado.
     * @param {String} dateString - La fecha a formatear.
     * @param {String} format - El formato de la fecha.
     * @returns {String} La fecha formateada.
     *
     * @example
     * formatDate('2023-07-23T00:00:00Z', 'YYYY-MM-DD') // Retorna '2023-07-23'
     */
    formatDate(dateString, format) {
      return moment(dateString).tz(this.timeZone).format(format)
    },

    /**
     * Prepara las amenidades para su asignación a editedItem.
     * @param {Array} amenidades - Las amenidades a preparar.
     * @returns {Array} Las amenidades preparadas.
     *
     * @example
     * prepareAmenidades([
     *   { producto_id: 1, producto: 'Producto 1', cantidad: 2, precio: 50 }
     * ]) // Retorna [{ _id: 1, nombre: 'Producto 1', cantidad: 2, precio: 50, total: 100 }]
     */
    prepareAmenidades(amenidades) {
      return amenidades.map(
        ({ producto_id: _id, producto: nombre, cantidad, precio, ...rest }) => ({
          _id,
          nombre,
          cantidad,
          precio,
          total: cantidad * precio,
          ...rest,
        })
      )
    },

    /**
     * Prepara los extras para su asignación a editedItem.
     * @param {Array} extras - Los extras a preparar.
     * @returns {Array} Los extras preparados.
     *
     * @example
     * prepareExtras([
     *   { extra_id: 1, extra: 'Extra 1', cantidad: 3, precio: 20 }
     * ]) // Retorna [{ _id: 1, nombre: 'Extra 1', cantidad: 3, precio: 20, total: 60 }]
     */
    prepareExtras(extras) {
      return extras.map(({ extra_id: _id, extra: nombre, cantidad, precio, ...rest }) => ({
        _id,
        nombre,
        cantidad,
        precio,
        total: cantidad * precio,
        ...rest,
      }))
    },

    /**
     * Cambia la dirección de 'Departure' a 'Arrival' o viceversa.
     * @param {String} direction - La dirección a cambiar.
     * @returns {String} La dirección cambiada.
     *
     * @example
     * switchDirection('Departure') // Retorna 'Arrival'
     */
    switchDirection(direction) {
      return direction === 'Departure' ? 'Arrival' : 'Departure'
    },

    /**
     * Determina la dirección y el hotel basándose en el traslado de ida.
     * @param {Object} trasladoIda - El objeto de traslado de ida.
     * @param {string} clave - La clave de los servicios.
     * @returns {string} La dirección.
     */
    getDirectionAndHotel(trasladoIda, clave) {
      let direction = null
      let hotel = null
      let hotel2 = null

      if (
        clave === 'hotel-apto' ||
        clave === 'hotel-apto-hotel' ||
        clave === 'hotel-hotel' ||
        clave === 'hotel-hotel-hotel'
      ) {
        hotel = this.hoteles.find((hotel) => hotel.hotel === trasladoIda.origen)
        direction = 'Departure'
      } else if (clave === 'apto-hotel' || clave === 'apto-hotel-apto') {
        hotel = this.hoteles.find((hotel) => hotel.hotel === trasladoIda.destino)
        direction = 'Arrival'
      }

      this.editedItem.hotel = hotel._id
      this.setZona(hotel.direccion.zona._id)

      // Obtiene el segundo hotel en caso de ser traslado hotel-hotel sencillo o redondo
      if (clave === 'hotel-hotel' || clave === 'hotel-hotel-hotel') {
        hotel2 = this.hoteles.find((hotel) => hotel.hotel === trasladoIda.destino)
        this.editedItem.hotel2 = hotel2._id
        this.setZona2(hotel2.direccion.zona._id)
        direction = null
      } else {
        this.editedItem.hotel2 = null
        // direction = null
      }

      return direction
    },

    mapearAmenidades(productos) {
      return productos.map((item) => {
        return {
          producto_id: item._id,
          producto: item.nombre,
          descripcion: item.descripcion,
          precio: item.precio,
          cantidad: item.cantidad,
        }
      })
    },

    mapearExtras(extras) {
      return extras.map((item) => {
        return {
          extra_id: item._id,
          extra: item.nombre,
          descripcion: item.descripcion,
          precio: item.precio,
          cantidad: item.cantidad,
        }
      })
    },

    /**
     * Procesa la lista de boletos, si existe.
     * @param {Object} servicios - El objeto de servicios.
     */
    processTickets(servicios) {
      if ('boletos' in servicios && servicios.boletos.length > 0) {
        servicios.boletos = servicios.boletos.map(({ cantidad, precio, ...rest }) => ({
          cantidad,
          precio,
          total: cantidad * precio,
          ...rest,
        }))
        this.initialCartBoletos = servicios.boletos
      }
    },

    sumarAmenidades: function (productos) {
      return productos.reduce((total, producto) => {
        return total + producto.cantidad * producto.precio
      }, 0)
    },

    crearTraslado(item, hotel, hotel2, amenidades, extras, esIda = true) {
      // console.log('Item')
      // console.log(item)
      const fecha = esIda ? item.fecha_ida : item.fecha_vuelta
      const hora = esIda ? item.hora_ida : item.hora_vuelta
      const pickup = esIda
        ? this.$store.state.transferReservation.pickup_ida
        : this.$store.state.transferReservation.pickup_vuelta

      let origen, destino, vuelo, aerolinea

      if (item.clave_traslado === 'hotel-hotel') {
        origen = hotel
        destino = hotel2
        // No hay información de vuelo y aerolínea para el traslado de hotel a hotel
        vuelo = ''
        aerolinea = ''
      } else {
        origen = esIda
          ? item.clave_traslado.startsWith('apto')
            ? 'Cancún Airport'
            : hotel
          : item.clave_traslado.startsWith('apto')
          ? hotel
          : 'Cancún Airport'

        destino = esIda
          ? item.clave_traslado.startsWith('apto')
            ? hotel
            : 'Cancún Airport'
          : item.clave_traslado.startsWith('apto')
          ? 'Cancún Airport'
          : hotel

        vuelo = esIda ? item.vuelo_ida : item.vuelo_vuelta
        aerolinea = esIda ? item.aerolinea_ida : item.aerolinea_vuelta
      }

      return {
        estatus: 'Pendiente',
        origen: origen,
        destino: destino,
        fecha: this.formatDateZone(fecha, hora, this.timeZone),
        hora: this.convertTo12HourFormat(hora),
        pickup: pickup,
        vuelo: vuelo,
        aerolinea: aerolinea,
        amenidades: amenidades,
        extras: extras,
      }
    },

    /**
     * Devuelve una fecha en formato ISO 8601 con la zona horaria especificada
     *
     * @param {string} fecha - La fecha en formato 'YYYY-MM-DD'.
     * @param {string} hora - La hora en formato 'HH:mm'.
     * @param {string} zona - La zona horaria en formato de IANA.
     *
     * @returns {string} - La fecha y hora especificadas en el formato ISO 8601 con la zona horaria especificada.
     *
     * @example
     * // returns '2023-05-26T22:30:00-05:00'
     * formatDateZone('2023-05-26', '22:30', 'America/Cancun');
     */
    formatDateZone(fecha, hora, zona) {
      const fechaHora = `${fecha}T${hora}`
      return moment.tz(fechaHora, zona).format()
    },
  },
}
