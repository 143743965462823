<template>
  <!-- Componente JSON Viewer -->
  <div class="json-viewer">
    <div v-for="(value, name, index) in json" :key="name" class="json-item">
      <p
        @click="isObjectOrArray(value) && toggleExpand(name + index)"
        :style="{
          'margin-bottom': '0.1rem',
          'font-weight': isObjectOrArray(value) ? 'bold' : 'normal',
        }"
      >
        <span
          class="json-key"
          :style="{ 'font-weight': 'bold', color: $vuetify.theme.currentTheme.primary }"
          >{{ name }}:</span
        >
        <span
          v-if="!isObjectOrArray(value)"
          class="json-value"
          :style="{ color: $vuetify.theme.currentTheme.secondary }"
          >{{ JSON.stringify(value) }}</span
        >
        <span
          v-else-if="expanded[name + index]"
          class="json-toggle"
          :style="{ color: $vuetify.theme.currentTheme.accent, cursor: 'pointer' }"
          >[-]</span
        >
        <span
          v-else
          class="json-toggle"
          :style="{ color: $vuetify.theme.currentTheme.accent, cursor: 'pointer' }"
          >[+]</span
        >
      </p>
      <json-viewer
        v-if="expanded[name + index] && isObjectOrArray(value)"
        :json="value"
        class="json-children"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'JsonViewer',
  props: {
    json: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      expanded: {},
    }
  },
  watch: {
    json: {
      handler() {
        this.expanded = {}
      },
      deep: true,
    },
  },
  methods: {
    /**
     * Verifica si un valor es un objeto o un array
     * @param {*} value - El valor a verificar
     * @returns {boolean} - Devuelve verdadero si el valor es un objeto o un array, falso de lo contrario
     */
    isObjectOrArray(item) {
      return item && typeof item === 'object'
    },

    /**
     * Alterna la propiedad de expansión para una clave específica
     * @param {string} key - La clave para expandir o contraer
     */
    toggleExpand(name) {
      this.$set(this.expanded, name, !this.expanded[name])
    },
  },
}
</script>

<style scoped>
.json-viewer {
  font-family: monospace;
  font-size: 0.85rem;
}

.json-item {
  margin-left: 20px;
}

.json-children {
  margin-left: 20px;
}
</style>
