<template>
  <base-material-tabs
    background-color="transparent"
    centered
    color="primary"
    icons-and-text
    v-model="tab"
  >
    <v-tab v-for="item in tabsCart" :key="item" class="mb-2">
      {{ item }}
    </v-tab>

    <!-- Ida -->
    <v-tab-item>
      <v-row class="mx-auto" justify="space-around" style="max-width: 600px">
        <!-- Autocomplete con los items disponibles (Productos, extras, etc.) -->
        <v-col cols="10">
          <v-autocomplete
            dense
            :item-text="getProductoText"
            item-value="_id"
            :label="label"
            maxlength="50"
            :prepend-icon="icon"
            v-model="productSelectedIda"
            :items="items"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="`${data.item.nombre} - ${data.item.descripcion}`"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="`Precio: USD ${data.item.precio}`"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <!-- Boton para agregar item -->
        <v-col cols="2">
          <v-btn class="mx-2" fab dark color="primary" x-small @click="agregarProductoIda">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>

        <!-- Tabla de items ida -->
        <v-col cols="12">
          <v-data-table
            dense
            hide-default-footer
            no-data-text="¡Agregue elementos al carrito!"
            :headers="headers"
            :items="cartIda"
          >
            <!-- Cantidad -->
            <template v-slot:[`item.cantidad`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                <v-edit-dialog
                  :return-value.sync="props.item.cantidad"
                  @save="save(props, 'ida')"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  {{ props.item.cantidad }}
                  <template v-slot:input>
                    <v-text-field
                      label="Cantidad"
                      min="1"
                      max="99"
                      maxlength="2"
                      single-line
                      type="number"
                      :rules="[max2chars]"
                      v-model="props.item.cantidad"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </span>
            </template>

            <!-- Nombre -->
            <template v-slot:[`item.nombre`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.nombre }}
              </span>
            </template>

            <!-- Descripción -->
            <template v-slot:[`item.descripcion`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.descripcion }}
              </span>
            </template>

            <!-- Precio -->
            <template v-slot:[`item.precio`]="props">
              <span class="cart-font-size font-weight-normal text-body"
                >{{ props.item.precio }} USD
              </span></template
            >

            <!-- Total -->
            <template v-slot:[`item.total`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.total }} USD
              </span></template
            >
          </v-data-table>
        </v-col>
      </v-row>
    </v-tab-item>

    <!-- Vuelta -->
    <v-tab-item v-if="redondo">
      <v-row class="mx-auto" justify="space-around" style="max-width: 600px">
        <!-- Autocomplete con los items disponibles (Productos, extras, etc.) -->
        <v-col cols="10">
          <v-autocomplete
            dense
            :item-text="getProductoText"
            item-value="_id"
            maxlength="50"
            :items="items"
            :label="label"
            :prepend-icon="icon"
            v-model="productSelectedVuelta"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="`${data.item.nombre} - ${data.item.descripcion}`"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="`Precio: USD ${data.item.precio}`"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <!-- Boton para agregar item -->
        <v-col cols="2">
          <v-btn class="mx-2" fab dark color="primary" x-small @click="agregarProductoVuelta">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>

        <!-- Tabla de items vuelta -->
        <v-col cols="12">
          <v-data-table
            dense
            hide-default-footer
            no-data-text="¡Agregue elementos al carrito!"
            :headers="headers"
            :items="cartVuelta"
          >
            <!-- Cantidad -->
            <template v-slot:[`item.cantidad`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                <v-edit-dialog
                  :return-value.sync="props.item.cantidad"
                  @save="save(props, 'vuelta')"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  {{ props.item.cantidad }}
                  <template v-slot:input>
                    <v-text-field
                      label="Cantidad"
                      min="1"
                      max="99"
                      maxlength="2"
                      single-line
                      type="number"
                      :rules="[max2chars]"
                      v-model="props.item.cantidad"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </span>
            </template>

            <!-- Nombre -->
            <template v-slot:[`item.nombre`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.nombre }}
              </span>
            </template>

            <!-- Descripción -->
            <template v-slot:[`item.descripcion`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.descripcion }}
              </span>
            </template>

            <!-- Precio -->
            <template v-slot:[`item.precio`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.precio }} USD
              </span></template
            >

            <!-- Total -->
            <template v-slot:[`item.total`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.total }} USD
              </span></template
            >
          </v-data-table>
        </v-col>
      </v-row>
    </v-tab-item>

    <!-- Boletos -->
    <v-tab-item v-if="haveBoletos">
      <v-row class="mx-auto" justify="space-around" style="max-width: 600px">
        <!-- Autocomplete con los items disponibles (Productos, extras, etc.) -->
        <v-col cols="10">
          <v-autocomplete
            dense
            :item-text="getBoletoText"
            item-value="_id"
            maxlength="50"
            :items="boletos"
            label="Boleto*"
            prepend-icon="mdi-ticket-confirmation"
            v-model="boletoSelected"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="`${data.item.ruta} - ${data.item.tipo_pasajero} - ${data.item.tipo}`"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="`Precio: USD ${data.item.precio}`"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <!-- Boton para agregar boletos -->
        <v-col cols="2">
          <v-btn class="mx-2" fab dark color="primary" x-small @click="agregarBoleto">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </v-col>

        <!-- Tabla de boletos -->
        <v-col cols="12">
          <v-data-table
            dense
            hide-default-footer
            no-data-text="¡Agregue boletos al carrito!"
            :headers="headersTickets"
            :items="cartBoletos"
          >
            <!-- Cantidad -->
            <template v-slot:[`item.cantidad`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                <v-edit-dialog
                  :return-value.sync="props.item.cantidad"
                  @save="save(props, 'boletos')"
                  @cancel="cancel"
                  @open="open"
                  @close="close"
                >
                  {{ props.item.cantidad }}
                  <template v-slot:input>
                    <v-text-field
                      label="Cantidad"
                      min="1"
                      max="99"
                      maxlength="2"
                      single-line
                      type="number"
                      :rules="[max2chars]"
                      v-model="props.item.cantidad"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </span>
            </template>

            <template v-slot:[`item.ruta`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.ruta }}
              </span>
            </template>

            <!-- <template v-slot:[`item.origen`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.origen }}
              </span>
            </template> -->

            <template v-slot:[`item.tipo_pasajero`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.tipo_pasajero }}
              </span>
            </template>

            <template v-slot:[`item.tipo`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.tipo }}
              </span>
            </template>

            <!-- Precio -->
            <template v-slot:[`item.precio`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.precio }} USD
              </span>
            </template>

            <!-- Total -->
            <template v-slot:[`item.total`]="props">
              <span class="cart-font-size font-weight-normal text-body">
                {{ props.item.total }} USD
              </span></template
            >
          </v-data-table>
        </v-col>
      </v-row>
    </v-tab-item>
  </base-material-tabs>
</template>

<script>
export default {
  name: 'Cart',
  props: {
    icon: { type: String, default: 'mdi-food' },
    items: { type: Array, default: null },
    label: { type: String, default: 'Producto*' },
    redondo: { type: Boolean, default: false },
    haveBoletos: { type: Boolean, default: false },
    boletos: { type: Array, default: null },
    initialCartIda: {
      type: Array,
      default: () => [],
    },
    initialCartVuelta: {
      type: Array,
      default: () => [],
    },
    initialCartBoletos: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    initialCartIda: {
      immediate: true,
      handler(newValue) {
        this.cartIda = newValue.slice()
      },
    },
    initialCartVuelta: {
      immediate: true,
      handler(newValue) {
        this.cartVuelta = newValue.slice()
      },
    },
    initialCartBoletos: {
      immediate: true,
      handler(newValue) {
        this.cartBoletos = newValue.slice()
      },
    },
  },

  data: () => ({
    max2chars: (v) => v.length <= 2 || 'Demasiados items en su carrito!',
    tab: 'Vuelta',
    productSelectedIda: -1,
    productSelectedVuelta: -1,
    boletoSelected: -1,
    cartIda: [],
    cartVuelta: [],
    cartBoletos: [],
    headers: [
      { text: 'Cantidad', value: 'cantidad' },
      { text: 'Nombre', value: 'nombre' },
      { text: 'Descripción', value: 'descripcion' },
      { text: 'Precio', value: 'precio' },
      { text: 'Total', value: 'total' }, // Agregamos la nueva columna
    ],
    headersTickets: [
      { text: 'Cantidad', value: 'cantidad' },
      { text: 'Ruta', value: 'ruta' },
      // { text: 'Origen', value: 'origen' },
      { text: 'Pasajero', value: 'tipo_pasajero' },
      { text: 'Tipo', value: 'tipo' },
      { text: 'Precio', value: 'precio' },
      { text: 'Total', value: 'total' }, // Agregamos la nueva columna
    ],
  }),

  computed: {
    tabsCart() {
      const tabs = this.redondo ? ['Ida', 'Vuelta'] : ['Ida']
      // Si la haveBoletos = true agrega boletos a los tabs
      if (this.haveBoletos) {
        tabs.push('Boletos')
      }
      return tabs
    },
  },

  methods: {
    /**
     * Añade un producto seleccionado al carrito del tipo especificado (Ida o Vuelta).
     * El producto se selecciona por su ID, y se emite un evento de 'cambio' con los detalles del producto y el carrito actualizado.
     * @param {string} tipo - El tipo de carrito ('Ida' o 'Vuelta') al que se va a añadir el producto.
     */
    agregarProducto(tipo) {
      const productoSeleccionado = this[`productSelected${tipo}`]
      const cart = this[`cart${tipo}`]

      if (productoSeleccionado === -1) {
        this.$store.commit('ALERT', {
          color: 'error',
          text: 'No ha seleccionado ningun producto',
        })
        return
      }

      if (cart.find((producto) => producto._id === productoSeleccionado)) {
        this.$store.commit('ALERT', {
          color: 'error',
          text: 'Ya existe este producto en el carro',
        })
        this[`productSelected${tipo}`] = -1
        return
      }

      const resultado = this.items.find((producto) => producto._id === productoSeleccionado)
      let producto = {
        _id: productoSeleccionado,
        nombre: resultado.nombre,
        descripcion: resultado.descripcion,
        precio: resultado.precio,
        cantidad: 1,
        total: resultado.precio, // Aquí agregamos el total, inicialmente será igual al precio
      }

      cart.push(producto)
      this[`productSelected${tipo}`] = -1
      this.$emit('change', { tipo: tipo.toLowerCase(), carro: cart })
    },

    /**
     * Añade un producto seleccionado al carrito de 'Ida'.
     * Emite un evento de 'cambio' con los detalles del producto y el carrito actualizado.
     */
    agregarProductoIda() {
      this.agregarProducto('Ida')
    },

    /**
     * Añade un producto seleccionado al carrito de 'Vuelta' si la propiedad 'redondo' es verdadera.
     * Emite un evento de 'cambio' con los detalles del producto y el carrito actualizado.
     */
    agregarProductoVuelta() {
      this.agregarProducto('Vuelta')
    },

    agregarBoleto() {
      if (this.boletoSelected === -1) {
        this.$store.commit('ALERT', {
          color: 'error',
          text: 'No ha seleccionado ningun boleto',
        })
        return
      }

      if (this.cartBoletos.find((boleto) => boleto._id === this.boletoSelected)) {
        this.$store.commit('ALERT', {
          color: 'error',
          text: 'Ya existe este boleto en el carro',
        })
        this.boletoSelected = -1
        return
      }

      const resultado = this.boletos.find((boleto) => boleto._id === this.boletoSelected)

      let boleto = {
        _id: resultado.id,
        cantidad: 1,
        ruta: resultado.ruta,
        origen: resultado.origen,
        tipo_pasajero: resultado.tipo_pasajero,
        tipo: resultado.tipo,
        precio: resultado.precio,
        total: resultado.precio,
      }
      console.log('Agrega Boleto')
      this.cartBoletos.push(boleto)
      this.boletoSelected = -1
      this.$emit('ticketChange', { carro: this.cartBoletos })
    },

    getBoletoText(boleto) {
      return `${boleto.ruta} - ${boleto.tipo}`
    },

    getProductoText(producto) {
      return `${producto.nombre} - ${producto.descripcion}`
    },

    /**
     * Actualiza la cantidad de elementos seleccionados y recalcula el total
     */
    save(props, tipo) {
      props.item.total = props.item.cantidad * props.item.precio

      switch (tipo) {
        case 'ida':
          this.$emit('change', { tipo: tipo, carro: this.cartIda })
          break
        case 'vuelta':
          this.$emit('change', { tipo: tipo, carro: this.cartVuelta })
          break
        default:
          this.$emit('ticketChange', { carro: this.cartBoletos })
          break
      }
      this.$store.commit('ALERT', {
        color: 'success',
        text: 'La cantidad ha sido actualizada!',
      })
    },
    cancel() {
      this.$store.commit('ALERT', {
        color: 'error',
        text: 'Cancelado',
      })
    },
    open() {
      // this.$store.commit('ALERT', {
      //   color: 'secondary',
      //   text: 'Modifique cantidad!',
      // })
      console.info('Dialog Open')
    },
    close() {
      console.log('Dialog closed')
    },
  },
}
</script>
<style scoped>
.cart-font-size {
  font-size: 11px;
}
</style>
