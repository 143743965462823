<template>
  <v-container id="reservations" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-book-open-outline"
      inline
      :title="$t('reservations')"
    >
      <v-spacer></v-spacer>
      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true"
          >Nuevo Traslado</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Contenedor para los elementos adicionales y búsqueda -->
      <div class="d-flex align-center" style="flex-direction: row; width: 100%">
        <!-- Contenedor para elementos adicionales -->
        <div style="width: 450px; display: flex; justify-content: space-between">
          <!-- v-select -->
          <!-- <v-select
            class="mr-10"
            :items="['Item1', 'Item2']"
            label="Seleccione"
            style="width: 120px"
          ></v-select> -->

          <!-- v-btn -->
          <!-- <v-btn color="primary" style="width: 120px">Botón</v-btn> -->

          <!-- v-switch -->
          <v-switch
            class="ml-5"
            style="width: 200px"
            v-model="isAscending"
            :label="`Ascendente: ${isAscending ? 'Si' : 'No'}`"
            @change="onOrderChange"
          ></v-switch>

          <!-- v-switch -->
          <v-switch
            class="ml-5"
            style="width: 250px"
            v-model="isAll"
            :label="`Todas las reservas: ${isAll ? 'Si' : 'No'}`"
            @change="onFilterOutdateChange"
          ></v-switch>
        </div>

        <!-- Textfield para la barra de Búsqueda -->
        <v-text-field
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Búsqueda"
          single-line
          style="max-width: 250px"
          v-model="search"
        />
      </div>

      <!-- Aqui van cancelar, selector de lenguaje y wizard -->

      <!-- Dialogo para mostrar JSON -->
      <v-dialog v-model="dialogJSON" max-width="600px">
        <v-card>
          <v-card-title>JSON Viewer</v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <json-viewer :json="selectedItem" />
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-actions>
            <v-btn
              @click="
                dialogJSON = false
                selectedItem = {}
              "
              color="primary"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogo para seleccionar lenguage -->
      <v-dialog v-model="dialogPDF" persistent width="250px">
        <v-card>
          <v-card-title>
            <span>Seleccionar idioma</span>
          </v-card-title>
          <v-card-text>
            <!-- Agrega un select para seleccionar el idioma -->
            <v-select
              v-model="selectedLanguage"
              :items="languageOptions"
              label="Idioma PDF"
              outlined
              dense
            ></v-select>
          </v-card-text>
          <v-card-actions class="justify-end">
            <!-- Agrega botones para cancelar y confirmar la selección -->
            <v-btn small @click="closeDialogLanguajePDF" color="error">Cancelar</v-btn>
            <v-btn small @click="obtenerPDF" color="primary">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogo para el wizard -->
      <v-dialog v-model="dialog" max-width="770px" persistent>
        <!-- Overlay para retroalimentar al usuario dentro del wizard-->
        <v-overlay :value="overlayDialog">
          <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
        </v-overlay>

        <!-- Wizard para venta de traslados -->
        <base-material-wizard
          class="mx-auto"
          v-model="tab"
          :available-steps="availableSteps"
          :items="tabs"
          :finishText="isEditing ? 'Actualizar' : 'Finalizar'"
          nextText="Siguiente"
          subTitle="Complete toda la información, esto nos ayudara a generar la reserva"
          :title="isEditing ? 'Editar reserva de traslados' : 'Reserva de traslados'"
          previousText="Anterior"
          @click:next="next($v)"
          @click:prev="tab--"
          @close="close"
        >
          <!-- Datos del clienteº -->
          <v-tab-item class="pb12">
            <form>
              <div class="text-center display-1 font-weight-light mb-6" v-if="!isEditing">
                Empecemos con información básica del cliente
              </div>

              <div class="text-center display-1 font-weight-light mb-6" v-else>
                Modifique la información del cliente
              </div>

              <v-row class="mx-auto" justify="space-around" style="max-width: 600px">
                <!-- Correo electrónico -->
                <v-col cols="12">
                  <v-text-field
                    dense
                    :disabled="isEditing"
                    label="Correo*"
                    maxlength="50"
                    prepend-icon="mdi-email"
                    required
                    v-model="$v.editedItem.correo.$model"
                    :error-messages="correoErrors"
                    @blur="$v.editedItem.correo.$touch(), buscarCliente()"
                    @input="$v.editedItem.correo.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Nombre -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Nombre*"
                    maxlength="20"
                    prepend-icon="mdi-account"
                    required
                    v-model="$v.editedItem.nombre.$model"
                    :error-messages="nombreErrors"
                    @blur="$v.editedItem.nombre.$touch()"
                    @input="$v.editedItem.nombre.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Apellido -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Apellido*"
                    maxlength="20"
                    prepend-icon="mdi-account"
                    required
                    v-model="$v.editedItem.apellido.$model"
                    :error-messages="apellidoErrors"
                    @blur="$v.editedItem.apellido.$touch()"
                    @input="$v.editedItem.apellido.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Código -->
                <v-col cols="6">
                  <v-autocomplete
                    dense
                    item-text="name"
                    item-value="dialCode"
                    label="Código*"
                    maxlength="20"
                    prepend-icon="mdi-code-braces"
                    required
                    v-model="$v.editedItem.codigo.$model"
                    :items="countryCodes"
                    :error-messages="codigoErrors"
                    @blur="$v.editedItem.codigo.$touch()"
                    @input="$v.editedItem.codigo.$reset()"
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="`${data.item.iso2} - (+${data.item.dialCode})`"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>

                <!-- Celular -->
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="Celular*"
                    maxlength="12"
                    prepend-icon="mdi-cellphone"
                    required
                    v-model="$v.editedItem.celular.$model"
                    :error-messages="celularErrors"
                    @blur="$v.editedItem.celular.$touch()"
                    @input="$v.editedItem.celular.$reset()"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>

                <!-- Genero -->
                <v-col cols="6">
                  <v-select
                    dense
                    :items="generos"
                    item-text="genero"
                    item-value="_id"
                    label="Genero*"
                    maxlength="20"
                    prepend-icon="mdi-gender-male-female"
                    required
                    v-model="$v.editedItem.genero.$model"
                    :error-messages="generoErrors"
                    @blur="$v.editedItem.genero.$touch()"
                    @input="$v.editedItem.genero.$reset()"
                  ></v-select>
                </v-col>
              </v-row>
            </form>
          </v-tab-item>

          <!-- Datos del traslado ✅ -->
          <v-tab-item class="pb12">
            <form>
              <v-responsive class="mx-auto" max-width="600">
                <div
                  class="text-center display-1 grey--text font-weight-light mb-6"
                  v-if="!isEditing"
                >
                  Ahora configure el tipo de traslado!
                </div>

                <div class="text-center display-1 grey--text font-weight-light mb-6" v-else>
                  Modifique el tipo de traslado!
                </div>

                <v-row class="mx-auto" justify="space-around" style="max-width: 600px">
                  <!-- Aeropuerto -->
                  <v-col cols="4">
                    <v-autocomplete
                      dense
                      :items="airportsItems"
                      item-text="text"
                      item-value="value"
                      label="Aeropuerto*"
                      prepend-icon="mdi-airport"
                      v-model="editedItem.aeropuerto"
                    ></v-autocomplete>
                  </v-col>
                  <!-- Tipo de traslado ✅ -->
                  <v-col cols="4">
                    <v-autocomplete
                      dense
                      item-text="traslado"
                      item-value="traslado"
                      label="Tipo de traslado*"
                      maxlength="20"
                      prepend-icon="mdi-train-car"
                      required
                      v-model="$v.editedItem.clave_traslado.$model"
                      :error-messages="tipoTrasladoErrors"
                      :items="tiposTraslado"
                      :menu-props="{ top: true, offsetY: true }"
                      @blur="$v.editedItem.clave_traslado.$touch()"
                      @change="onTipoTrasladoChange"
                      @input="$v.editedItem.clave_traslado.$reset()"
                    ></v-autocomplete>
                  </v-col>

                  <!-- Adultos ✅ -->
                  <v-col cols="2">
                    <v-text-field
                      dense
                      label="Adultos*"
                      max="99"
                      maxlength="2"
                      min="1"
                      prepend-icon="mdi-account"
                      required
                      type="number"
                      v-model="$v.editedItem.adultos.$model"
                      :error-messages="adultosErrors"
                      @blur="$v.editedItem.adultos.$touch()"
                      @change="onTextChange"
                      @input="$v.editedItem.adultos.$reset()"
                    ></v-text-field>
                  </v-col>

                  <!-- Menores ✅ -->
                  <v-col cols="2">
                    <v-text-field
                      dense
                      label="Niños*"
                      max="99"
                      maxlength="2"
                      min="0"
                      prepend-icon="mdi-account-child"
                      required
                      type="number"
                      v-model="$v.editedItem.menores.$model"
                      :error-messages="menoresErrors"
                      @blur="$v.editedItem.menores.$touch()"
                      @change="onTextChange"
                      @input="$v.editedItem.menores.$reset()"
                    ></v-text-field>
                  </v-col>

                  <!-- Hotel ✅ -->
                  <v-col v-if="editedItem.clave_traslado !== 'hotel-hotel'" cols="12">
                    <v-autocomplete
                      dense
                      item-text="hotel"
                      item-value="_id"
                      label="Hotel*"
                      maxlength="20"
                      prepend-icon="mdi-bed"
                      required
                      v-model="$v.editedItem.hotel.$model"
                      :error-messages="hotelErrors"
                      :items="hoteles"
                      @blur="$v.editedItem.hotel.$touch()"
                      @change="onHotelChange"
                      @input="$v.editedItem.hotel.$reset()"
                    ></v-autocomplete>
                  </v-col>

                  <!-- Si el traslado es hotel-hotel muestra hotel origen -->
                  <!-- Hotel origen ✅ -->
                  <v-col v-if="editedItem.clave_traslado === 'hotel-hotel'" cols="6">
                    <v-autocomplete
                      dense
                      item-text="hotel"
                      item-value="_id"
                      label="Hotel Origen*"
                      maxlength="20"
                      prepend-icon="mdi-bed"
                      required
                      v-model="$v.editedItem.hotel.$model"
                      :error-messages="hotelErrors"
                      :items="hoteles"
                      @blur="$v.editedItem.hotel.$touch()"
                      @change="onHotelChange"
                      @input="$v.editedItem.hotel.$reset()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col v-if="editedItem.clave_traslado === 'hotel-hotel'" cols="6">
                    <v-autocomplete
                      dense
                      item-text="hotel"
                      item-value="_id"
                      label="Hotel Destino*"
                      maxlength="20"
                      prepend-icon="mdi-bed"
                      required
                      v-model="$v.editedItem.hotel2.$model"
                      :error-messages="hotel2Errors"
                      :items="hoteles"
                      @blur="$v.editedItem.hotel2.$touch()"
                      @change="onHotel2Change"
                      @input="$v.editedItem.hotel2.$reset()"
                    ></v-autocomplete>
                  </v-col>

                  <!-- Fecha Ida ✅ -->
                  <v-col cols="4">
                    <v-menu
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                      v-model="fromDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          dense
                          :label="labelFechaIda"
                          :value="dateDisp"
                          :error-messages="fechaIdaErrors"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                          @blur="$v.editedItem.fecha_ida.$touch()"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="es-MX"
                        no-title
                        v-model="$v.editedItem.fecha_ida.$model"
                        :min="minDate"
                        :max="maxDateDisp"
                        @change="onDateChange('ida')"
                        @input="fromDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- Hora Ida ✅ -->
                  <v-col :key="labelHoraIdaKey" cols="4">
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="editedItem.hora_ida"
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      ref="menui"
                      transition="scale-transition"
                      v-model="menuTimeIda"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :error-messages="horaErrors"
                          :label="labelHoraIda"
                          dense
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-model="$v.editedItem.hora_ida.$model"
                          v-bind="attrs"
                          v-on="on"
                          @blur="$v.editedItem.hora_ida.$touch()"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="24hr"
                        full-width
                        v-if="menuTimeIda"
                        v-model="$v.editedItem.hora_ida.$model"
                        @change="onDateChange('ida')"
                        @click:minute="$refs.menui.save(editedItem.hora_ida)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>

                  <!-- Pickup Ida ✅ -->
                  <v-col cols="4">
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="editedItem.pickup_ida"
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      ref="menupi"
                      transition="scale-transition"
                      v-model="menuPickupIda"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :error-messages="pickupIdaErrors"
                          :label="labelPickupIda"
                          dense
                          prepend-icon="mdi-hail"
                          readonly
                          v-model="$v.editedItem.pickup_ida.$model"
                          v-bind="attrs"
                          v-on="on"
                          @blur="$v.editedItem.pickup_ida.$touch()"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="24hr"
                        full-width
                        v-if="menuPickupIda"
                        v-model="$v.editedItem.pickup_ida.$model"
                        @click:minute="$refs.menupi.save(editedItem.pickup_ida)"
                        @input="onPickupChange('ida')"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>

                  <!-- Aerolinea Ida ✅ -->
                  <v-col cols="6" v-if="haveFlights">
                    <v-combobox
                      :items="idaAirlines"
                      :label="labelAerolineaIda"
                      :prepend-icon="idaIcon"
                      dense
                      maxlength="20"
                      v-model="editedItem.aerolinea_ida"
                      @change="onChangeAirline(editedItem.aerolinea_ida, 'ida')"
                    ></v-combobox>
                  </v-col>

                  <!-- Vuelo Ida ✅ -->
                  <v-col cols="6" v-if="haveFlights">
                    <v-combobox
                      :items="idaFligtsNumbers"
                      :label="labelVueloIda"
                      :prepend-icon="idaIcon"
                      dense
                      maxlength="20"
                      v-model="editedItem.vuelo_ida"
                    ></v-combobox>
                  </v-col>

                  <!-- Fecha Vuelta ✅ -->
                  <v-col cols="4" v-if="isRedondo">
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                      v-model="fromDateMenuVuelta"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :error-messages="fechaVueltaErrors"
                          :label="labelFechaVuelta"
                          :value="dateDispVuelta"
                          dense
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                          @blur="$v.editedItem.fecha_vuelta.$touch()"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :max="maxDateDisp"
                        :min="minDate"
                        locale="es-MX"
                        no-title
                        v-model="$v.editedItem.fecha_vuelta.$model"
                        @change="onDateChange('vuelta')"
                        @input="fromDateMenuVuelta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- Hora Vuelta ✅ -->
                  <v-col cols="4" v-if="isRedondo">
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="editedItem.hora_vuelta"
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      ref="menuv"
                      transition="scale-transition"
                      v-model="menuTimeVuelta"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :error-messages="horaVueltaErrors"
                          :label="labelHoraVuelta"
                          dense
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-model="$v.editedItem.hora_vuelta.$model"
                          v-on="on"
                          @blur="$v.editedItem.hora_vuelta.$touch()"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="24hr"
                        full-width
                        v-if="menuTimeVuelta"
                        v-model="$v.editedItem.hora_vuelta.$model"
                        @click:minute="$refs.menuv.save(editedItem.hora_vuelta)"
                        @change="onDateChange('vuelta')"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>

                  <!-- Pickup Vuelta ✅ -->
                  <v-col cols="4" v-if="isRedondo">
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="editedItem.pickup_vuelta"
                      max-width="290px"
                      min-width="290px"
                      offset-y
                      ref="menupv"
                      transition="scale-transition"
                      v-model="menuPickupVuelta"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :error-messages="pickupVueltaErrors"
                          :label="labelPickpupVuelta"
                          dense
                          prepend-icon="mdi-hail"
                          readonly
                          v-model="$v.editedItem.pickup_vuelta.$model"
                          v-bind="attrs"
                          v-on="on"
                          @blur="$v.editedItem.pickup_vuelta.$touch()"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="24hr"
                        full-width
                        v-if="menuPickupVuelta"
                        v-model="editedItem.pickup_vuelta"
                        @click:minute="$refs.menupv.save(editedItem.pickup_vuelta)"
                        @input="onPickupChange('vuelta')"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>

                  <!-- Aerolinea Vuelta ✅ -->
                  <v-col cols="6" v-if="isRedondo">
                    <v-combobox
                      :items="vueltaAirLines"
                      :label="labelAerolineaVuelta"
                      :prepend-icon="vueltaIcon"
                      dense
                      maxlength="20"
                      v-model="editedItem.aerolinea_vuelta"
                      @change="onChangeAirline(editedItem.aerolinea_vuelta, 'vuelta')"
                    ></v-combobox>
                  </v-col>

                  <!-- Vuelo Vuelta ✅ -->
                  <v-col cols="6" v-if="isRedondo">
                    <v-combobox
                      :items="vueltaFligtsNumbers"
                      :label="labelVueloVuelta"
                      :prepend-icon="vueltaIcon"
                      dense
                      filterable
                      create-item
                      maxlength="20"
                      v-model="editedItem.vuelo_vuelta"
                    ></v-combobox>
                  </v-col>
                </v-row>
              </v-responsive>
            </form>
          </v-tab-item>

          <!-- Datos del servicio -->
          <v-tab-item class="pb12">
            <form>
              <v-responsive class="mx-auto" max-width="600">
                <div class="text-center display-1 grey--text font-weight-light mb-6">
                  Seleccione el servicio deseado!
                </div>

                <v-row class="mx-auto" justify="space-around" style="max-width: 600px">
                  <v-container fluid>
                    <v-radio-group
                      v-model="$v.editedItem.tipo_vehiculo.$model"
                      v-if="tarifas.length > 0"
                      :error-messages="tipoVehiculoErrors"
                      @blur="$v.editedItem.tipo_vehiculo.$touch()"
                    >
                      <div style="max-height: 300px; overflow-y: auto">
                        <v-col cols="12" v-for="(tarifa, index) in tarifas" :key="index">
                          <v-card class="mx-auto" max-width="500">
                            <v-list-item three-line>
                              <v-list-item-avatar tile size="80">
                                <ThumborImage
                                  :imageUrl="tarifa.tipo_vehiculo.imagen"
                                  :avatarSize="80"
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title class="text-h5 mb-1">
                                  {{
                                    tarifa.traducciones.es.tipo_vehiculo +
                                    ' ' +
                                    '(' +
                                    tarifa.unidades +
                                    ' Unidades)'
                                  }}
                                </v-list-item-title>
                                <div>
                                  <v-radio :value="tarifa.tipo_vehiculo.tipo">
                                    <template v-slot:label>
                                      <div>
                                        <strong class="primary--text">{{
                                          tarifa.traducciones.es.descripcion
                                        }}</strong>
                                      </div>
                                    </template>
                                  </v-radio>
                                </div>
                                <v-list-item-subtitle>USD {{ tarifa.precio }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-col>
                      </div>
                    </v-radio-group>
                  </v-container>
                </v-row>
              </v-responsive>
            </form>
          </v-tab-item>

          <!-- Datos de las amenidades -->
          <v-tab-item class="pb12">
            <form>
              <v-responsive class="mx-auto" max-width="600">
                <div class="text-center display-1 grey--text font-weight-light mb-6">
                  Agregue las amenidades y boletos del ferry!
                </div>

                <Cart
                  @change="actualizaProductos"
                  @ticketChange="actualizaBoletos"
                  :boletos="haveTickets ? boletos : null"
                  :haveBoletos="haveTickets"
                  :initialCartBoletos="initialCartBoletos"
                  :initialCartIda="initialCartIda"
                  :initialCartVuelta="initialCartVuelta"
                  :items="productos"
                  :redondo="isRedondo"
                ></Cart>
              </v-responsive>
            </form>
          </v-tab-item>

          <!-- Datos de los extras -->
          <v-tab-item class="pb12">
            <form>
              <v-responsive class="mx-auto" max-width="600">
                <div class="text-center display-1 grey--text font-weight-light mb-6">
                  Agregue los extras!
                </div>

                <Cart
                  :icon="`mdi-baby-carriage`"
                  :initialCartIda="initialExtrasIda"
                  :initialCartVuelta="initialExtrasVuelta"
                  :items="extras"
                  label="Extra*"
                  :redondo="isRedondo"
                  @change="actualizaExtras"
                ></Cart>
              </v-responsive>
            </form>
          </v-tab-item>

          <!-- Notas de la reserva -->
          <v-tab-item class="pb12">
            <form>
              <v-responsive class="mx-auto" max-width="600">
                <div class="text-center display-1 grey--text font-weight-light mb-6">
                  Agregue una nota si es necesario!
                </div>
                <v-textarea
                  outlined
                  name="nota"
                  label="Notas:"
                  value=""
                  v-model="editedItem.nota"
                ></v-textarea>
              </v-responsive>
            </form>
          </v-tab-item>

          <!-- Datos del pago -->
          <v-tab-item class="pb12">
            <form>
              <v-responsive class="mx-auto" max-width="600">
                <div class="text-center display-1 grey--text font-weight-light mb-6">
                  Seleccione el metodo de pago!
                </div>

                <!-- Muestra los diferentes tipos de pago de acuerdo al array payments -->
                <v-row class="mx-auto" justify="space-around" style="max-width: 600px">
                  <v-container fluid>
                    <v-item-group v-model="editedItem.payment" class="row">
                      <v-item v-for="payment in payments" :key="payment.type">
                        <template v-slot="{ active, toggle }">
                          <v-col class="text-center" cols="4">
                            <v-card
                              :class="active ? 'success--text' : 'grey--text'"
                              class="mb-6 mx-auto pa-10 d-inline-block v-card--account"
                              outlined
                              @click="toggle"
                            >
                              <v-icon large v-text="payment.icon" />
                            </v-card>

                            <div
                              class="text-uppercase subtitle-2 text--primary"
                              v-text="payment.type"
                            />
                          </v-col>
                        </template>
                      </v-item>
                    </v-item-group>
                    <!-- <v-col cols="12" class="d-flex justify-center">
                      <v-btn fab color="teal" class="ma-2"
                        ><v-icon size="24"> mdi-note-edit </v-icon></v-btn
                      >
                    </v-col> -->
                  </v-container>
                </v-row>
              </v-responsive>
            </form>
          </v-tab-item>
        </base-material-wizard>
      </v-dialog>

      <!-- Tabla de datos de Traslados -->
      <v-data-table
        dense
        item-key="_id"
        loading-text="Cargando... Espere por favor"
        show-expand
        x-small
        :custom-filter="customFilter"
        :expanded.sync="expanded"
        :headers="headers"
        :items="traslados"
        :loading="isLoading"
        :search.sync="search"
        :single-expand="singleExpand"
      >
        <!-- Slot fecha y hora -->
        <template v-slot:[`item.createdAt`]="{ item }">
          <!-- <span>{{ new Date(item.createdAt).toLocaleString() }}</span> -->
          <DateTime :datetime="item.createdAt" />
        </template>

        <!-- Slot del nombre y correo -->
        <template v-slot:[`item.nombre`]="{ item }">
          <UserInfo :nombre="item.nombre" :apellidos="item.apellido" :correo="item.correo" />
        </template>

        <!-- Slot de contacto -->
        <template v-slot:[`item.celular`]="{ item }">
          <ContactInfo :cel="item.codigo + ' ' + item.celular" />
        </template>

        <!-- Slot tipo de vehiculo y traslado -->
        <template v-slot:[`item.tipo`]="{ item }">
          <div
            class="icon-container"
            style="display: flex; flex-direction: row; align-items: flex-start"
          >
            <!-- Tipo de vehiculo -->
            <TeslaIcon
              v-if="getIconoVehiculo(item.servicios[0].tipo_vehiculo) === 'icono-tesla'"
              iconWidth="20"
              iconHeight="20"
            ></TeslaIcon>
            <v-icon v-else :color="getColorIconoVehiculo(item.servicios[0].tipo_vehiculo)">{{
              getIconoVehiculo(item.servicios[0].tipo_vehiculo)
            }}</v-icon>
            <!-- Tipo de traslado -->
            <v-icon :color="getColorIconoTraslado(item)" class="ml-2">
              {{ getIconoTraslado(item) }}
            </v-icon>
          </div>
        </template>

        <!-- Slot Conditional Icon Tipo Pago -->
        <template v-slot:[`item.pago.tipo`]="{ item }">
          <PaypalIcon
            class="mr-1"
            iconWidth="16px"
            iconHeight="16px"
            v-if="item.pago.tipo === 'paypal'"
          />
          <ConditionalIcon
            class="mr-1"
            :texto="item.pago.tipo"
            cond="cash"
            primaryIcon="mdi-cash-multiple"
            secondaryIcon="mdi-credit-card-outline"
            primaryColor="green"
            secondaryColor="blue"
            size="small"
            v-else
          />
          <ConditionalIcon
            :texto="item.estatus"
            cond="Pagado"
            primaryIcon="mdi-check-decagram"
            secondaryIcon="mdi-check-decagram-outline"
            size="small"
          />
        </template>

        <!-- Slot Conditional Icon Estatus Pago -->
        <!-- <template v-slot:[`item.estatus`]="{ item }">
          <ConditionalIcon
            :texto="item.estatus"
            cond="Pagado"
            primaryIcon="mdi-check-decagram"
            secondaryIcon="mdi-check-decagram-outline"
            size="small"
          />
        </template> -->

        <template v-slot:[`item.nota`]="{ item }">
          <span :style="{ fontSize: '12px' }" class="font-weight-normal text-body">
            {{ item.nota }}
          </span>
        </template>

        <!-- Slot de Acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-1" small @click="sendMail(item)">mdi-email-fast-outline</v-icon>
          <v-icon class="mr-1" color="red" small @click="openDialogPDF(item)"
            >mdi-file-pdf-box</v-icon
          >
          <v-icon class="mr-1" color="orange" small @click="showJSON(item)">mdi-code-json</v-icon>
          <v-icon class="mr-1" color="black" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon color="brown" small @click="deleteItem(item)">mdi-trash-can-outline</v-icon>
        </template>

        <!-- Empieza expanded item -->
        <template v-slot:[`expanded-item`]="{ headers, item }">
          <td
            :colspan="headers.length / 4.5"
            style="font-size: 83%; padding: 5px 5px 5px 20px; vertical-align: top"
          >
            <h5>INFORMACIÓN DE PAGO:</h5>
            <b>Importe:</b> <i>{{ item.pago.importe }}</i> <br />
            <b>Moneda:</b> <i>{{ item.pago.moneda }}</i> <br />
            <b>Tipo:</b> <i>{{ item.pago.tipo }}</i> <br />
            <b>Referencia:</b> <i>{{ item.pago.referencia }}</i> <br />
          </td>

          <td
            :colspan="headers.length / 4"
            style="font-size: 83%; padding: 5px 5px 5px 20px; vertical-align: top"
          >
            <h5>NOTA:</h5>
            <i>{{ item.nota }}</i
            ><br />
          </td>
        </template>
      </v-data-table>
      <v-divider class="mt-3" />
    </base-material-card>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import moment from 'moment'
import 'moment-timezone'
import { required, minLength, maxLength, email, requiredIf } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { notificationMixin } from '@/mixins/notificationMixin'
import { payloadMixin } from '@/mixins/reservasMixin'
import iconosTrasladoMixin from '@/mixins/iconosTrasladoMixin'

import DateTime from '@/components/general/DateTime.vue'
import Cart from '@/components/general/Cart.vue'
import ConditionalIcon from '@/components/general/ConditionalIcon.vue'
import ContactInfo from '@/components/general/ContactInfo.vue'
import PaypalIcon from '@/components/icons/PaypaIcon.vue'
import TeslaIcon from '@/components/icons/TeslaIcon.vue'
import ThumborImage from '@/components/general/ThumborImage.vue'
import UserInfo from '@/components/general/UserInfo.vue'
import JsonViewer from '@/components/general/JsonViewer.vue' // Importa el componente JSON Viewer

import countryCodes from '../reservas/countryCodes.js'
import flights from '../../../../../vuelos.json'

export default {
  name: 'ReservaTraslados',
  components: {
    DateTime,
    Cart,
    ConditionalIcon,
    ContactInfo,
    TeslaIcon,
    PaypalIcon,
    ThumborImage,
    UserInfo,
    JsonViewer,
  },
  data: () => ({
    changed: false,
    checkbox: false,
    countryCodes: countryCodes,
    defaultButtonText: 'Cargar imagen del producto',
    dialog: false,
    dialogPDF: false, // Muestra u oculta dialogo para seleccionar idioma PDF
    dialogJSON: false, // Muestra el visor de json
    editedIndex: -1,
    fromDateMenu: false,
    fromDateMenuVuelta: false,
    isAll: true,
    isAscending: false,
    isEditing: false,
    isLoading: false,
    isSelecting: false,
    isSure: false,
    labelAerolineaIda: 'Aerolinea',
    labelFechaIda: 'Fecha*',
    labelHoraIda: 'Hora*',
    labelHoraIdaKey: 'apto',
    labelPickupIda: 'Pickup*',
    labelVueloIda: 'Vuelo*',
    labelAerolineaVuelta: 'Aerolinea',
    labelFechaVuelta: 'Fecha*',
    labelHoraVuelta: 'Hora*',
    labelPickpupVuelta: 'Pickup*',
    labelVueloVuelta: 'Vuelo*',
    languageOptions: [
      { text: 'Español', value: 'es' },
      { text: 'Inglés', value: 'en' },
    ], // Opciones disponibles para el select de idioma
    menuTimeIda: false,
    menuPickupIda: false,
    menuTimeVuelta: false,
    menuPickupVuelta: false,
    minDate: new Date().toISOString().substr(0, 10),
    mnuMecanica: false,
    overlay: false,
    overlayDialog: false,
    radios: 'Tarifas',
    registered: false,
    reveal: false,
    search: undefined,
    selectedLanguage: 'es',
    selectedItem: null, //Elemento seleccionado para generar PDF
    singleExpand: true,
    productSelected: -1,
    tab: 0,
    timeZone: 'America/Cancun',
    airportsItems: [],
    // Informacion inicial del carrito
    initialCartBoletos: [],
    initialCartIda: [],
    initialCartVuelta: [],
    initialExtrasIda: [],
    initialExtrasVuelta: [],
    // payment: null, // Se pasa dendro de los edited y default items
    boletos: [],
    // boletosCart: [], // Se pasa dendro de los edited y default items
    // Información de vuelos
    idaFlights: [],
    idaFligtsNumbers: [],
    vueltaFlights: [],
    vueltaFligtsNumbers: [],
    // Información de extras
    extras: [],
    // extrasida: [], // Se pasa dentro de los edited y default items
    // extrasvuelta: [], // Se pasa dentro de los edited y default items
    expanded: [],
    generos: [],
    hoteles: [],
    headers: [
      { text: 'Fecha Reserva', value: 'createdAt', width: '8%' },
      { text: 'Nombre', value: 'nombre', sortable: false },
      { text: 'Correo', value: 'correo', sortable: false, align: ' d-none' },
      { text: 'Tipo', value: 'tipo', sortable: false },
      // { text: 'Servicio', value: 'servicios[0].tipo_traslado', sortable: false },
      { text: 'Pax', value: 'servicios[0].pasajeros', sortable: false },
      { text: 'Contacto', value: 'celular', sortable: false },
      { text: 'Info. Pago', value: 'pago.tipo', sortable: false },
      // { text: 'Nota', value: 'nota', sortable: false },
      { sortable: false, text: 'Acciones', value: 'actions', width: '12%' },
      { text: '', value: 'data-table-expand' },
    ],
    payments: [
      {
        icon: 'mdi-alpha-p-circle-outline',
        type: 'paypal',
        id: 0,
      },
      {
        icon: 'mdi-credit-card-outline',
        type: 'creditCard',
        id: 1,
      },
      {
        icon: 'mdi-cash',
        type: 'cash',
        id: 2,
      },
    ],
    productos: [],
    // productosida: [], // Se pasa dentro de los edited y default items
    // productosvuelta: [], // Se pasa dentro de los edited y default items
    tabs: ['Cliente', 'Traslado', 'Servicio', 'Amenidades', 'Extras', 'Notas', 'Pago'],
    tarifas: [], // Se pasa dentro de los edited y default items y luego se saca
    tiposTraslado: [],
    traslados: [],
    zonas: [],
    defaultItem: {
      _id: '',
      aeropuerto: '',
      cliente: '',
      correo: '',
      nombre: '',
      apellido: '',
      codigo: '',
      celular: '',
      genero: '',
      contacto_adicional: '',
      //servicios: [],
      servicios: [{ tipo_traslado: '' }],
      pago: {},
      nota: '',
      origen: '',
      estatus: '',
      activo: true,
      createdAt: '',
      createdBy: '',
      createdByAgency: '',
      clave_traslado: '',
      tipo_vehiculo: null,
      hotel: '',
      hotel2: '',
      adultos: 1,
      menores: 0,
      fecha_ida: null,
      fecha_vuelta: null,
      hora: null,
      hora_ida: null,
      hora_vuelta: null,
      pickup_ida: null,
      pickup_vuelta: null,
      vuelo_ida: '',
      vuelo_vuelta: '',
      aerolinea_ida: '',
      aerolinea_vuelta: '',
      boletosCart: [], // Estaba afuera
      extrasida: [], // Estaba afuera
      extrasvuelta: [], // Estaba afuera
      payment: null, // Estaba afuera
      productosida: [], // Estaba afuera
      productosvuelta: [], // Estaba afuera
      // tarifas: [], // Estaba afuera y se volvio a sacar
    },
    editedItem: {
      _id: '',
      aeropuerto: '',
      cliente: '',
      correo: '',
      nombre: '',
      apellido: '',
      codigo: '',
      celular: '',
      genero: '',
      contacto_adicional: '',
      servicios: [{ tipo_traslado: '' }],
      pago: {},
      nota: '',
      origen: '',
      estatus: '',
      activo: true,
      createdAt: '',
      createdBy: '',
      createdByAgency: '',
      clave_traslado: '',
      tipo_vehiculo: null,
      hotel: '',
      hotel2: '',
      adultos: 1,
      menores: 0,
      fecha_ida: null,
      fecha_vuelta: null,
      hora: null,
      hora_ida: null,
      hora_vuelta: null,
      pickup_ida: null,
      pickup_vuelta: null,
      vuelo_ida: '',
      vuelo_vuelta: '',
      aerolinea_ida: '',
      aerolinea_vuelta: '',
      boletosCart: [], // Estaba afuera
      extrasida: [], // Estaba afuera
      extrasvuelta: [], // Estaba afuera
      payment: null, // Estaba afuera
      productosida: [], // Estaba afuera
      productosvuelta: [], // Estaba afuera
      // tarifas: [], // Estaba afuera y se volvio a sacar
    },
  }),
  mixins: [iconosTrasladoMixin, notificationMixin, payloadMixin, validationMixin],

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  mounted() {
    this.obtenerAeropuertos()
    this.setTipoTraslado('sencillo')
    this.obtenerTraslados()
    this.obtenerHoteles()
    this.obtenerBoletosFerry()
    this.obtenerExtras()
    this.obtenerGeneros()
    this.obtenerTiposTraslado()
    this.obtenerProductos()
    this.obtenerZonas()
  },

  created() {
    console.log('Suscribiendo al socket new_booking')
    this.$socket.$subscribe('new_booking', (payload) => {
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
      console.log(payload)
      this.isAscending ? this.traslados.push(payload.data) : this.traslados.unshift(payload.data)
      this.playNotificationSound()
    })
  },

  beforeDestroy() {
    // removing existing listener
    console.log('Desuscribiendo del socket new_booking')
    // this.$socket.$unsubscribe('new_booking')
  },

  destroyed() {
    console.log('El componente ha sido destruido')
  },

  // sockets: {
  //   connect() {
  //     console.log('socket connected')
  //   },
  //   srvReservationCreated(val) {
  //     console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
  //     console.log(val)
  //   },
  // },

  computed: {
    /**
     * @description Obtiene las aerolineas para el traslado de ida
     *
     * @return {array} Aerolineas de llegada o salida para el traslado de ida
     */
    idaAirlines() {
      if (this.editedItem.clave_traslado.includes('apto')) {
        if (this.editedItem.clave_traslado.startsWith('apto')) {
          // Obtiene informacion de llegadas para el traslado de ida
          if (this.idaFlights.arrivals === undefined) return []
          const airlines = [...new Set(this.idaFlights.arrivals.map((x) => x.airline.name))]
          airlines.sort()
          return airlines
        }
        // Obtiene informacion de salidas para el traslado de ida
        if (this.idaFlights.departures === undefined) return []
        const airlines = [...new Set(this.idaFlights.departures.map((x) => x.airline.name))]
        airlines.sort()
        return airlines
      }
      return []
    },

    /**
     * @description Determina el icono para el traslado de ida
     *
     * @return {string} Nombre del icono de material icons
     */
    idaIcon() {
      const clave_traslado = this.editedItem.clave_traslado
      if (clave_traslado.includes('apto')) {
        return clave_traslado.startsWith('apto') ? 'mdi-airplane-landing' : 'mdi-airplane-takeoff'
      }
      return 'mdi-airplane-landing'
    },

    /**
     * @description Obtiene las aerolineas para el traslado de vuelta
     *
     * @return {array} Aerolineas de llegada o salida para el traslado de vuelta
     */
    vueltaAirLines() {
      if (this.editedItem.clave_traslado.includes('apto')) {
        if (this.editedItem.clave_traslado.startsWith('apto')) {
          // Obtiene información de salidas para el traslado de vuelta
          if (this.vueltaFlights.departures === undefined) return []
          const airlines = [...new Set(this.vueltaFlights.departures.map((x) => x.airline.name))]
          airlines.sort()
          return airlines
        }
        // Obtiene informacion de llegadas para el traslado de vuelta
        if (this.vueltaFlights.arrivals === undefined) return []
        const airlines = [...new Set(this.vueltaFlights.arrivals.map((x) => x.airline.name))]
        airlines.sort()
        return airlines
      }
      return []
    },

    /**
     * @description Determina el icono para el traslado de vuelta
     *
     * @return {string} Nombre del icono de material icons
     */
    vueltaIcon() {
      const clave_traslado = this.editedItem.clave_traslado
      if (clave_traslado.includes('apto')) {
        return clave_traslado.startsWith('hotel') ? 'mdi-airplane-landing' : 'mdi-airplane-takeoff'
      }
      return 'mdi-airplane-takeoff'
    },

    /**
     * @description Calcula el número de pasos o pestañas del tab bar
     *
     * @return {array} arreglo con los pasos del tab bar
     */
    availableSteps() {
      const steps = [0]

      if (
        !this.$v.editedItem.correo.$invalid &&
        !this.$v.editedItem.nombre.$invalid &&
        !this.$v.editedItem.apellido.$invalid &&
        !this.$v.editedItem.codigo.$invalid &&
        !this.$v.editedItem.celular.$invalid &&
        !this.$v.editedItem.genero.$invalid
      )
        steps.push(1)

      if (
        !this.$v.editedItem.clave_traslado.$invalid &&
        !this.$v.editedItem.adultos.$invalid &&
        !this.$v.editedItem.menores.$invalid &&
        !this.$v.editedItem.hotel.$invalid &&
        !this.$v.editedItem.fecha_ida.$invalid &&
        !this.$v.editedItem.hora_ida.$invalid &&
        !this.$v.editedItem.fecha_vuelta.$invalid &&
        !this.$v.editedItem.hora_vuelta.$invalid &&
        steps.includes(1)
      )
        steps.push(2)

      if (!this.$v.editedItem.tipo_vehiculo.$invalid && steps.includes(2)) {
        steps.push(3)
        steps.push(4)
        steps.push(5)
        steps.push(6)
      }

      if (!this.$v.editedItem.payment.$invalid && steps.includes(6)) {
        steps.push(7)
      }
      // this.save()
      return steps
    },

    /**
     * @description muestra la fecha de ida
     *
     * @return {string} devuelve string en formato fecha YYY-MM-DD
     */
    dateDisp() {
      return this.editedItem.fecha_ida
    },

    /**
     * @description muestra la fecha de vuelta
     *
     * @return {string} devuelve string en formato fecha YYY-MM-DD
     */
    dateDispVuelta() {
      return this.editedItem.fecha_vuelta
    },

    /**
     * @description
     *
     * @return {bool} verdadero si no hay productos seleccionados o falso si hay productos
     * seleccionados
     */
    isDisabled() {
      if (this.productSelected === -1) {
        return true
      } else {
        return false
      }
    },

    /**
     * @description
     *
     * @return {bool} verdadero si esta libre de errores el formulario o falso si hay errores
     */
    isActive() {
      if (!this.$v.$dirty) {
        return true
      }
      return false
    },

    /**
     * @description Calcula la fecha máxima para poder realizar una reserva
     * en este caso en particular un año posterior a la fecha de la reserva
     *
     * @return {date} fecha un año posterior a la fecha actual
     */
    maxDateDisp() {
      const date = new Date() //.toISOString().substr(0, 10)
      const year = date.getFullYear() + 1
      const month = date.getMonth() + 1
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      console.debug(year + '-' + (month < 10 ? '0' + month : month) + '-' + day)
      return year + '-' + (month < 10 ? '0' + month : month) + '-' + day
    },

    /**
     * @description
     *
     * @return {string} alcance
     */
    scope() {
      if (this.tab === 0) return 'cliente'
      else if (this.tab === 1) return 'traslado'
      else if (this.tab === 2) return 'servicio'
      else if (this.tab === 3) return 'amenidades'
      else if (this.tab === 4) return 'extras'
      else if (this.tab === 5) return 'notas'
      return 'pago'
    },

    /**
     * @description Obtiene del store el tipo de traslado de la reserva
     *
     * @return {bool} verdadero si es redondo o falso si es sencillo
     */
    isRedondo() {
      return this.$store.state.transferReservation.tipo_traslado === 'redondo' ? true : false
    },

    /**
     * @description Determina si el tipo de traslado tiene vuelos
     *
     * @return {bool} verdadero si tiene vuelos, de lo contrario devuelve falso
     */
    haveFlights() {
      return this.editedItem.clave_traslado && this.editedItem.clave_traslado.includes('apto')
        ? true
        : false
    },

    /**
     * Propiedad computada que verifica si se deben ofrecer tickets.
     *
     * Busca las zonas de los hoteles seleccionados en el array de zonas y
     * verifica si alguna de las zonas tiene boletos disponibles.
     *
     * @returns {boolean} true si se deben ofrecer tickets, false en caso contrario.
     */
    haveTickets() {
      let ticketsAviable = false

      const zona1 = this.zonas.find((zona) => zona._id === this.zona)
      if (zona1 && zona1.boletos) {
        ticketsAviable = true
      }

      // Solo buscar en zona2 si zona1 no tiene boletos y zona2 está definida
      if (!ticketsAviable && this.zona2) {
        const zona2 = this.zonas.find((zona) => zona._id === this.zona2)
        if (zona2 && zona2.boletos) {
          ticketsAviable = true
        }
      }

      return ticketsAviable
    },

    //*********/ Computo de errores ********//
    adultosErrors() {
      const errors = []
      if (!this.$v.editedItem.adultos.$dirty) return errors
      !this.$v.editedItem.adultos.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.adultos.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracteres.')
      !this.$v.editedItem.adultos.maxLength &&
        errors.push('Este campo debe de tener un máximo de 2 caracteres.')
      return errors
    },
    apellidoErrors() {
      const errors = []
      if (!this.$v.editedItem.apellido.$dirty) return errors
      !this.$v.editedItem.apellido.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.apellido.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.apellido.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    celularErrors() {
      const errors = []
      if (!this.$v.editedItem.celular.$dirty) return errors
      !this.$v.editedItem.celular.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.celular.minLength &&
        errors.push('Este campo debe de tener un mínimo de 8 caracteres.')
      !this.$v.editedItem.celular.maxLength &&
        errors.push('Este campo debe de tener un máximo de 12 caracteres.')
      return errors
    },
    codigoErrors() {
      const errors = []
      if (!this.$v.editedItem.codigo.$dirty) return errors
      !this.$v.editedItem.codigo.required && errors.push('Este campo es requerido')
      return errors
    },
    correoErrors() {
      const errors = []
      if (!this.$v.editedItem.correo.$dirty) return errors
      !this.$v.editedItem.correo.email && errors.push('Debe ser un correo electrónico válido')
      !this.$v.editedItem.correo.required && errors.push('El correo electrónico es requerido')
      return errors
    },
    fechaIdaErrors() {
      const errors = []
      if (!this.$v.editedItem.fecha_ida.$dirty) return errors
      !this.$v.editedItem.fecha_ida.required && errors.push('Este campo es requerido')
      return errors
    },
    fechaVueltaErrors() {
      const errors = []
      if (!this.$v.editedItem.fecha_vuelta.$dirty) return errors
      !this.$v.editedItem.fecha_vuelta.required && errors.push('Este campo es requerido')
      return errors
    },
    pickupIdaErrors() {
      const errors = []
      if (!this.$v.editedItem.pickup_ida.$dirty) return errors
      !this.$v.editedItem.pickup_ida.required && errors.push('Este campo es requerido')
      return errors
    },
    pickupVueltaErrors() {
      const errors = []
      if (!this.$v.editedItem.pickup_vuelta.$dirty) return errors
      !this.$v.editedItem.pickup_vuelta.required && errors.push('Este campo es requerido')
      return errors
    },
    generoErrors() {
      const errors = []
      if (!this.$v.editedItem.genero.$dirty) return errors
      !this.$v.editedItem.genero.required && errors.push('Este campo es requerido')
      return errors
    },
    horaErrors() {
      const errors = []
      if (!this.$v.editedItem.hora_ida.$dirty) return errors
      !this.$v.editedItem.hora_ida.required && errors.push('Este campo es requerido')
      return errors
    },
    horaVueltaErrors() {
      const errors = []
      if (!this.$v.editedItem.hora_vuelta.$dirty) return errors
      !this.$v.editedItem.hora_vuelta.required && errors.push('Este campo es requerido')
      return errors
    },
    hotelErrors() {
      const errors = []
      if (!this.$v.editedItem.hotel.$dirty) return errors
      !this.$v.editedItem.hotel.required && errors.push('Este campo es requerido')
      return errors
    },
    hotel2Errors() {
      const errors = []
      if (!this.$v.editedItem.hotel2.$dirty) return errors
      !this.$v.editedItem.hotel2.required && errors.push('Este campo es requerido')
      return errors
    },
    menoresErrors() {
      const errors = []
      if (!this.$v.editedItem.menores.$dirty) return errors
      !this.$v.editedItem.menores.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.menores.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracteres.')
      !this.$v.editedItem.menores.maxLength &&
        errors.push('Este campo debe de tener un máximo de 2 caracteres.')
      return errors
    },
    nombreErrors() {
      const errors = []
      if (!this.$v.editedItem.nombre.$dirty) return errors
      !this.$v.editedItem.nombre.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.nombre.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.nombre.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    pagoErrors() {
      const errors = []
      if (!this.$v.editedItem.payment.$dirty) return errors
      !this.$v.editedItem.payment.required && errors.push('Este campo es requerido')
      return errors
    },
    tipoTrasladoErrors() {
      const errors = []
      if (!this.$v.editedItem.clave_traslado.$dirty) return errors
      !this.$v.editedItem.clave_traslado.required && errors.push('Este campo es requerido')
      return errors
    },
    tipoVehiculoErrors() {
      const errors = []
      if (!this.$v.editedItem.tipo_vehiculo.$dirty) return errors
      !this.$v.editedItem.tipo_vehiculo.required && errors.push('Este campo es requerido')
      return errors
    },

    ...mapState('transferReservation', {
      tickets: (state) => state.tickets,
      zona: (state) => state.zona,
      zona2: (state) => state.zona2,
    }),
  },

  validations: {
    editedItem: {
      correo: { required, email },
      nombre: { required, minLength: minLength(3), maxLength: maxLength(20) },
      apellido: { required, minLength: minLength(3), maxLength: maxLength(20) },
      codigo: { required },
      celular: { required, minLength: minLength(8), maxLength: maxLength(12) },
      genero: { required },
      clave_traslado: { required },
      tipo_vehiculo: { required },
      adultos: { required, minLength: minLength(1), maxLength: maxLength(2) },
      menores: { required, minLength: minLength(1), maxLength: maxLength(2) },
      hotel: { required },
      hotel2: {
        required: requiredIf((editedItem) => {
          if (editedItem.clave_traslado === 'hotel-hotel') return true
          return false
        }),
      },
      fecha_ida: { required },
      fecha_vuelta: {
        required: requiredIf((editedItem) => {
          if (editedItem.servicios[0].tipo_traslado === 'redondo') return true
          return false
        }),
      },
      hora_ida: { required },
      hora_vuelta: {
        required: requiredIf((editedItem) => {
          if (editedItem.servicios[0].tipo_traslado === 'redondo') return true
          return false
        }),
      },
      pickup_ida: { required },
      pickup_vuelta: {
        required: requiredIf((editedItem) => {
          if (editedItem.servicios[0].tipo_traslado === 'redondo') return true
          return false
        }),
      },
      payment: { required },
    },
  },

  methods: {
    /**
     * @description Actualiza los arrays del carrito de boletos
     * si es que el traslado abarca una zona que ofrece boletos
     * para el ferry
     */
    actualizaBoletos(info) {
      this.editedItem.boletosCart = info.carro
      // console.log(this.editedItem.boletosCart)
    },

    /**
     * @description Actualiza los datos del cliente si existe o lo crea
     * si no existe
     */
    acualizaOCreaCliente() {
      let payload = {
        body: {
          nombre: this.editedItem.nombre,
          apellido: this.editedItem.apellido,
          correo: this.editedItem.correo,
          contacto: {
            codigo: this.editedItem.codigo,
            celular: this.editedItem.celular,
          },
          genero: this.editedItem.genero,
        },
      }
      if (this.editedItem.cliente === '') {
        this.$store
          .dispatch('client/addClient', payload)
          .then((response) => {
            this.editedItem.cliente = response.data._id
          })
          .catch((err) => {
            this.isLoading = false
            this.$store.commit('ALERT', {
              color: 'error',
              text: err.response.data.error,
              // Enviar a inicio de sesión
            })
          })
      } else {
        payload.id = this.editedItem.cliente
        this.$store
          .dispatch('client/editClient', payload)
          .then(() => {
            // this.$store.commit('ALERT', {
            //   color: 'success',
            //   text: 'Se actualizo base de datos de clientes',
            //   // Enviar a inicio de sesión
            // })
          })
          .catch((err) => {
            this.isLoading = false
            this.$store.commit('ALERT', {
              color: 'error',
              text: err.response.data.error,
              // Enviar a inicio de sesión
            })
          })
      }
    },

    /**
     * @description Actualiza los arrays del carrito de productos segun el traslado
     * que se este realizando en caso de viajes redondos ya sea productos
     * para el traslado de ida o para el traslado de vuelta
     */
    actualizaExtras(info) {
      info.tipo === 'ida'
        ? (this.editedItem.extrasida = info.carro)
        : (this.editedItem.extrasvuelta = info.carro)
    },

    /**
     * @description Actualiza los arrays del carrito de productos segun el traslado
     * que se este realizando en caso de viajes redondos ya sea productos
     * para el traslado de ida o para el traslado de vuelta
     */
    actualizaProductos(info) {
      info.tipo === 'ida'
        ? (this.editedItem.productosida = info.carro)
        : (this.editedItem.productosvuelta = info.carro)
    },

    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let payload = {}
      payload.id = this.editedItem._id
      this.$store
        .dispatch('transferReservation/deleteTansferReservation', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description Busca un cliente existente
     */
    async buscarCliente() {
      if (!this.$v.editedItem.correo.$invalid) {
        if (!this.isEditing) {
          console.info('buscarCliente')
          await this.obtenerCliente()
        }
      }
    },

    /**
     * Limpia los datos almacenados en el store de Vuex.
     * Restaura los valores predeterminados para varios campos del estado.
     *
     * @returns {void}
     */
    clearStore() {
      this.setTipoTraslado('sencillo')
      this.setPickupIda('')
      this.setPickupVuelta('')
      this.setTickets(false)
      this.setZona('')
      this.setZona2('')
    },

    /**
     * @descripcion Cierra el dialogo con el wizard de reservas
     */
    close() {
      this.dialog = false
      this.isEditing = false
      this.tab = 0
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.clearStore()
        this.tarifas = []
        this.initialCartIda = []
        this.initialCartVuelta = []
        this.initialCartBoletos
      })
      this.$v.$reset()
    },

    /**
     * @descripcion Cierra el dialogo para escoger el idioma del PDF
     */
    closeDialogLanguajePDF() {
      this.dialogPDF = false
    },

    customFilter(value, search, item) {
      return (
        (item.nombre != null &&
          search != null &&
          typeof item.nombre === 'string' &&
          item.nombre.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1) ||
        (item.correo != null &&
          search != null &&
          typeof item.correo === 'string' &&
          item.correo.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1)
      )
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.traslados.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.traslados.splice(index, 1)
      }
    },

    /**
     * @description Obtiene el indice del elemento seleccionado y lo signa al array editedItem
     * @param {object} item elemento seleccionado
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.traslados.indexOf(item)
      // console.log(`Editando ${this.editedIndex}`)
      // this.editedItem = Object.assign({}, item)
      if (this.validateTransferDate(item)) {
        this.setEditedItem(item)
        this.dialog = true
      } else {
        this.$store.commit('ALERT', {
          color: 'error',
          text: 'Esta reserva no puede ser modificada',
          // Enviar a inicio de sesión
        })
        // this.editedItem = Object.assign({}, item)
      }
    },

    /**
     * @description Evalua el caracter tecleado para saber si es un numero
     */
    isNumber: function (evt, negativo = false) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode

      if (negativo && charCode === 45) {
        return true
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 //&&
        // charCode !== 45
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    /**
     * @description Reinicia los campos relacionados con la información de vuelos
     */
    limpiaInfoVuelos() {
      this.idaFlights = []
      this.idaFligtsNumbers = []
      this.vueltaFlights = []
      this.vueltaFligtsNumbers = []
      this.editedItem.aerolinea_ida = null
      this.editedItem.vuelo_ida = null
      this.editedItem.aerolinea_vuelta = null
      this.editedItem.vuelo_vuelta = null
      this.editedItem.fecha_ida = null
      this.$v.editedItem.fecha_ida.$reset()
      this.editedItem.hora_ida = null
      this.$v.editedItem.hora_ida.$reset()
      this.editedItem.fecha_vuelta = null
      this.$v.editedItem.fecha_vuelta.$reset()
      this.editedItem.hora_vuelta = null
      this.$v.editedItem.hora_vuelta.$reset()
    },

    creaPayload() {
      let totalAmenidades = 0
      let totalBoletos = 0

      // Obtiene el tipo de traslado (sencillo|redondo) del store
      const tipo_traslado = this.$store.state.transferReservation.tipo_traslado

      // Busca la tarifa para el vehiculo seleccionado dentro del arreglo
      // de tarifas
      const traslado = this.tarifas.find(
        (tarifa) => tarifa.tipo_vehiculo.tipo === this.editedItem.tipo_vehiculo
      )

      // Busca el hotel seleccionado dentro del arreglo
      // de hoteles
      const destino = this.hoteles.find((hotel) => hotel._id === this.editedItem.hotel)
      // Verifica si el tipo de traslado es 'hotel-hotel y obtiene el hotel
      const hotel2 =
        this.editedItem.clave_traslado === 'hotel-hotel'
          ? this.hoteles.find((hotel) => hotel._id === this.editedItem.hotel2)
          : null

      // Obtiene las amenidades del traslado de ida para formar el payload
      let productosida = this.mapearAmenidades(this.editedItem.productosida)

      // Obtiene el importe total de los productos del traslado de ida
      // y lo suma al importe de las amenidades
      productosida.forEach((producto) => {
        totalAmenidades += producto.cantidad * producto.precio
      })

      // Obtiene los extras del traslado de ida para formar el payload
      let extrasida = this.mapearExtras(this.editedItem.extrasida)

      // Forma el payload para el traslado de ida
      let trasladoIda = this.crearTraslado(
        this.editedItem,
        destino.hotel,
        hotel2 !== null ? hotel2.hotel : null,
        productosida,
        extrasida
      )

      // Agrega el payload al array de traslados
      let traslados = [trasladoIda]

      // ********** TRASLADOS REDONDOS **********
      // Verifica si el tipo de traslado es redondo, si es verdadero
      // forma el payload para el traslado de vuelta
      if (tipo_traslado === 'redondo') {
        // Obtiene las amenidades del traslado de vuelta para formar el payload
        let productosvuelta = this.mapearAmenidades(this.editedItem.productosvuelta)

        // Obtiene el importe total de los productos del traslado de vueltaº
        // y lo suma al importe de las amenidades
        productosvuelta.forEach((producto) => {
          totalAmenidades += producto.cantidad * producto.precio
        })

        // Obtiene los extras del traslado de vuelta para formar el payload
        let extrasvuelta = this.mapearExtras(this.editedItem.extrasvuelta)

        // Forma el payload para el traslado de vuelta
        let trasladoVuelta = this.crearTraslado(
          this.editedItem,
          destino.hotel,
          hotel2 !== null ? hotel2.hotel : null,
          productosvuelta,
          extrasvuelta,
          false
        )
        traslados.push(trasladoVuelta)
      }

      // Caclula importe total de boletos del ferry
      this.editedItem.boletosCart.forEach((boleto) => {
        totalBoletos += boleto.cantidad * boleto.precio
      })

      // Forma el payload para los servicios
      let servicios = {
        aeropuerto: this.editedItem.aeropuerto,
        incluir_asientos_menores: this.editedItem.menores > 0 ? true : false,
        tipo_traslado: this.$store.state.transferReservation.tipo_traslado,
        tipo_vehiculo: this.editedItem.tipo_vehiculo,
        clave: this.editedItem.clave_traslado,
        pasajeros: this.editedItem.adultos + '.' + this.editedItem.menores,
        precio: traslado.precio,
        traslados: traslados,
        boletos: this.editedItem.boletosCart,
      }

      // Busca el tipo de pago seleccionado dentro del array
      // de tipos de pago
      let tipo = this.payments.find((payment) => payment.id === this.editedItem.payment)

      // Forma el payload para el pago
      let pago = {
        tipo: tipo.type,
        referencia: '',
        importe: traslado.precio + totalAmenidades + totalBoletos,
        moneda: 'USD',
      }

      // Forma el payload para la resreva
      let payload = {
        cliente: this.editedItem.cliente,
        correo: this.editedItem.correo,
        nombre: this.editedItem.nombre,
        apellido: this.editedItem.apellido,
        codigo: '+' + this.editedItem.codigo,
        celular: this.editedItem.celular,
        genero: this.editedItem.genero,
        /* contacto_adicional: {
            correo: this.editedItem.correo,
            nombre: this.editedItem.nombre,
            apellido: this.editedItem.apellido,
            codigo: this.editedItem.codigo,
            celular: this.editedItem.celular,
            genero: this.editedItem.genero,
          }, */
        servicios: [servicios],
        pago: pago,
        tipo_cambio: 1,
        nota: this.editedItem.nota,
        origen: 'Dashboard',
        estatus: 'Pendiente',
        idioma: 'en',
      }
      this.save(payload)
    },

    /**
     * @description Maneja el evento click sobre el boton next dependiendo del
     * tab en que se encuentre
     */
    async next(valid) {
      if (!valid) return

      // Si es el la ultima pestaña se preara para crear el payload
      if (this.tab === this.tabs.length - 1) {
        this.creaPayload()
      } else {
        // console.info(`This tab: ${this.tab}`)
        if (this.tab === 0 && this.registered === false) {
          // console.info('Registrar usuario')
          this.acualizaOCreaCliente()
        }
        if (this.tab === 1) await this.obtenerTarifas()
        this.tab++
      }
    },

    /**
     * Verifica si ya esta la lista de aeropuertos en el store, si no esta la carga y la asigna
     * al array de aeropuertos
     */
    async obtenerAeropuertos() {
      if (this.$store.state.airports.airports.length === 0) {
        await this.$store.dispatch('airports/getAirports')
      }
      this.airportsItems = this.$store.state.airports.airports
    },

    obtenerBoletosFerry() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('ferryTicket/fetchFerryTicket', payload)
        .then((response) => {
          const data = response.data
          const transformedData = data.reduce((result, item) => {
            Object.entries(item.precios).forEach(([tipo, precio]) => {
              result.push({
                _id: `${item._id}${tipo.charAt(0)}`,
                id: item._id,
                tipo_pasajero: item.tipo,
                ruta: item.ruta,
                origen: item.origen,
                tipo: tipo,
                precio: precio,
              })
            })

            return result
          }, [])
          this.boletos = transformedData
          // this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
          })
        })
    },

    /**
     * @description Obtiene el cliente a traves de su correo electronico
     */
    obtenerCliente() {
      this.overlayDialog = true
      let payload = {
        query: {
          correo: this.editedItem.correo,
        },
      }
      this.$store
        .dispatch('client/fetchClientByQuery', payload)
        .then((response) => {
          if (response.data.length === 1) {
            this.editedItem.nombre =
              response.data[0].nombre === undefined ? null : response.data[0].nombre
            this.editedItem.apellido =
              response.data[0].apellido === undefined ? null : response.data[0].apellido
            this.editedItem.celular =
              response.data[0].contacto.celular === undefined
                ? null
                : response.data[0].contacto.celular
            this.editedItem.codigo =
              response.data[0].contacto.codigo === undefined
                ? null
                : response.data[0].contacto.codigo
            this.editedItem.genero =
              response.data[0].genero === undefined ? null : response.data[0].genero
            this.editedItem.cliente = response.data[0]._id
          }
          this.overlayDialog = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene un listado de extras
     */
    async obtenerExtras() {
      // this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('extra/fetchExtras', payload)
        .then((response) => {
          // Se cambia el nombre de la clave extra por nombre para usarlo en el
          // componente Cart.vue de manera generica
          var str = JSON.stringify(response.data)
          str = str.replace(/extra/g, 'nombre')
          this.extras = JSON.parse(str) //response.data
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene el listado de generos
     */
    async obtenerGeneros() {
      // this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('gender/fetchGenders', payload)
        .then((response) => {
          this.generos = response.data
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error al tratar de obtener generos',
          })
        })
    },

    /**
     * @description Obtiene el listado de hoteles
     */
    obtenerHoteles() {
      // this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('hotel/fetchHotels', payload)
        .then((response) => {
          this.hoteles = response.data
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error al tratar de obtener hoteles',
          })
        })
    },

    /**
     * Obtiene la hora de pickup al resta una cantidad determinada de horas a
     * una hora dada dependiendo de la zona.
     *
     * @param {string} fecha - La fecha original en formato 'YYYY-MM-DD'
     * @param {string} hora - La hora original en formato 'HH:mm'.
     * @param {string} zona - La zona horaria del lugar de pickup
     * @param {string} traslado - La dirección del traslado ida|vuelta
     *
     * @example
     * // Restar 3.5 horas a '23:35'
     * this.obtenerPickup('2023-05-26', '23:35', 'America/Cancun', 'ida');
     *
     * @returns {string} La hora del pickup en formato 'HH:mm'.
     */
    async obtenerPickup(fecha, hora, zona, traslado) {
      // Crear un objeto moment con la fecha y hora original de llegada o salida de la reserva
      const fechaHora = moment.tz(`${fecha}T${hora}`, zona)
      try {
        const zona = this.$store.state.transferReservation.zona
        const payload = { id: zona }
        const pickup = await this.$store.dispatch('zone/pickupZone', payload)

        // Calcula la hora de pickup restando las horas para el pickup de la zona
        const nuevaHora = fechaHora.subtract(pickup.data.pickup, 'hours')

        // Llama a setPickupHora para guardar la hora en el store
        this.setPickupHora(nuevaHora, traslado)

        return nuevaHora.format('HH:mm')
      } catch (error) {
        console.log(error)
        return `${fecha}T${hora}`
      }
    },

    /**
     * Obtiene un PDF y lo muestra en una nueva pestaña del navegador.
     * @param {Object} item - El objeto item que contiene información necesaria para obtener el PDF.
     * @returns {void}
     *
     * @example
     * // Ejemplo de uso:
     * const item = {
     *   _id: '12345',
     *   // ... otras propiedades necesarias para obtener el PDF
     * };
     * obtenerPDF(item);
     */
    obtenerPDF() {
      const params = `?locale=${this.selectedLanguage}`

      const payload = {
        params: params,
        id: this.selectedItem._id,
      }

      this.$store
        .dispatch('transferReservation/getPDF', payload)
        .then((response) => {
          // Crear una URL a partir del blob del PDF
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          )

          // Abrir una nueva pestaña con el PDF
          window.open(url)
          this.dialogPDF = false
        })
        .catch((error) => {
          // Manejar el error
          console.error(error)
        })
    },

    /**
     * @description Obtiene el listado de productos
     */
    obtenerProductos() {
      // this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('product/fetchProduct', payload)
        .then((response) => {
          // Se cambia el nombre de la clave producto por nombre para usarlo en el
          // componente Cart.vue de manera generica
          var str = JSON.stringify(response.data)
          str = str.replace(/producto/g, 'nombre')
          this.productos = JSON.parse(str) //response.data
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error al tratar de obtener productos',
          })
        })
    },

    /**
     * @description Obtiene las tarifas del traslado
     */
    obtenerTarifas() {
      this.overlayDialog = true
      // Obtiene tipo de traslado (sencillo/redondo)
      const tipo_traslado = this.$store.state.transferReservation.tipo_traslado
      const zona = this.$store.state.transferReservation.zona
      const zona2 = this.$store.state.transferReservation.zona2
      let payload = {}

      if (this.editedItem.clave_traslado !== 'hotel-hotel') {
        payload = {
          tipo_traslado: tipo_traslado,
          pasajeros: parseFloat(this.editedItem.adultos + '.' + this.editedItem.menores),
          origen: this.editedItem.clave_traslado.startsWith('apto') ? '' : zona,
          destino: this.editedItem.clave_traslado.startsWith('apto') ? zona : '',
        }
      } else {
        payload = {
          tipo_traslado: tipo_traslado,
          pasajeros: parseFloat(this.editedItem.adultos + '.' + this.editedItem.menores),
          origen: zona,
          destino: zona2,
        }
      }

      this.$store
        .dispatch('rate/getRatesTransfer', payload)
        .then((response) => {
          this.tarifas = response.data
          this.overlayDialog = false
        })
        .catch((err) => {
          this.overlayDialog = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error al tratar de obtener tarifas',
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene el listado de tipos de traslado
     */
    async obtenerTiposTraslado() {
      let payload = {}
      this.$store
        .dispatch('transfer/fetchTransfer', payload)
        .then((response) => {
          this.tiposTraslado = response.data
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error al tratar de obtener tipo traslados',
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene el listado de reservas de traslados
     */
    async obtenerTraslados() {
      this.isLoading = true
      let payload = {
        params: '?campoOrden=createdAt&tipoOrden=desc',
      }
      if (!this.isAll) {
        payload.params += '&filterOutdated=true'
      }
      this.$store
        .dispatch('transferReservation/fetchTransferReservation', payload)
        .then((response) => {
          this.traslados = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error al tratar de obtener traslados',
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene informacion de todos los vuelos en una fecha y rango de tiempo
     *
     * @param {string} from - Cadena en formato fecha YYY-MM-DDTHH:MM para indicar inicio de rango
     * @param {string} to - Cadena en formato fecha YYY-MM-DDTHH:MM para indicar fin de rango
     * @param {string} direction - Cadena para indicar direccion de vuelos [arrival, departure]
     * @param {string} tipo - Tipo de traslado [ida, vuelta] para el cual se buscan los vuelos
     */
    async obtenerVuelos(from, to, direction, tipo) {
      let payload = {
        from: from,
        to: to,
        params: '?direction=' + direction,
      }
      this.$store
        .dispatch('aerodatabox/fetchFlights', payload)
        .then((response) => {
          // console.log(response.data)
          tipo === 'ida' ? (this.idaFlights = response.data) : (this.vueltaFlights = response.data)
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error con la API de vuelo',
          })
        })
    },

    /**
     * Obtiene los vuelos chafas.
     * @param {string} from - La fecha y hora de origen en formato ISO 8601.
     * @param {string} to - La fecha y hora de destino en formato ISO 8601.
     * @param {string} direction - La dirección del vuelo (llegada o salida).
     * @param {string} tipo - El tipo de vuelo (ida/vuelta).
     * @returns {void}
     * @example
     * obtenerVuelosChafa('2023-05-24T20:00:00-06:00', '2023-05-24T22:00:00-06:00', 'Arrival', 'ida');
     */
    obtenerVuelosChafa(from, to, direction, tipo) {
      // console.log(from + '/' + to + '?direction=' + direction)
      if (tipo === 'ida') {
        direction === 'Arrival'
          ? (this.idaFlights = { arrivals: flights.arrivals })
          : (this.idaFlights = { departures: flights.departures })
      } else {
        direction === 'Arrival'
          ? (this.vueltaFlights = { arrivals: flights.arrivals })
          : (this.vueltaFlights = { departures: flights.departures })
      }
    },

    /**
     * @description Obtiene el listado de zonas
     */
    obtenerZonas() {
      // this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('zone/fetchZones', payload)
        .then((response) => {
          this.zonas = response.data
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error al tratar de obtener las zonas',
          })
        })
    },

    /**
     * @description Obtiene los numeros de vuelo al seleccionar una aerolinea
     *
     * @param {string} airline - aerolinea de para obtener números de vuelo
     * @param {string} tipo - Tipo de traslado [ida, vuelta] para el cual es el
     * número de vuelo
     *
     * @return {array} Números de vuelo
     */
    onChangeAirline(airline, tipo) {
      this.changed = true
      if (tipo === 'ida') {
        this.editedItem.vuelo_ida = null
        const vuelos = this.editedItem.clave_traslado.startsWith('apto')
          ? this.idaFlights.arrivals
          : this.idaFlights.departures
        this.idaFligtsNumbers = []
        let flightsNumber = vuelos.filter((element) => element.airline.name === airline)
        flightsNumber = [...new Set(flightsNumber.map((x) => x.number))]
        this.idaFligtsNumbers = flightsNumber
      } else {
        this.editedItem.vuelo_vuelta = null
        const vuelos = this.editedItem.clave_traslado.startsWith('apto')
          ? this.vueltaFlights.departures
          : this.vueltaFlights.arrivals
        this.vueltaFligtsNumbers = []
        let flightsNumber = vuelos.filter((element) => element.airline.name === airline)
        flightsNumber = [...new Set(flightsNumber.map((x) => x.number))]
        this.vueltaFligtsNumbers = flightsNumber
      }
    },

    /**
     * Método asincrónico que se dispara cuando cambia la fecha. Actualiza la propiedad 'changed' a verdadero.
     * Combina la fecha y hora seleccionadas y establece las fechas de recogida y de vuelo correspondientes dependiendo del tipo de traslado.
     *
     * @async
     * @param {string} tipo - Representa el tipo de traslado, puede ser 'ida' o 'vuelta'.
     *
     * @returns {undefined} No retorna nada si la fecha o la hora no están definidas.
     *
     * @example
     * // Si la fecha de ida se cambia a 2023-07-05 y la hora de ida es 12:00,
     * // el método actualiza el valor de 'pickup_ida' dependiendo de 'tipo_traslado'.
     * onDateChange('ida')
     */
    async onDateChange(tipo) {
      this.changed = true

      const date = this.editedItem[`fecha_${tipo}`]
      const time = this.editedItem[`hora_${tipo}`]

      if (!date || !time) return

      // Crea las constantes para obtener el vuelo
      const from = `${date}T${time}` // Fecha de legada del PAX y Hora de llegada en formato ISO
      const to = moment(from).add(12, 'hours').format('YYYY-MM-DDTHH:mm') // Suma 12 horas para obtener el rango

      // Verifica si el traslado incluye el aeropuerto
      if (this.editedItem.clave_traslado.includes('apto')) {
        // Determina si es una llegada o no
        const isArrival = this.editedItem.clave_traslado.startsWith(
          tipo === 'ida' ? 'apto' : 'hotel'
        )
        // Establece la direccion del traslado
        const direction = isArrival ? 'Arrival' : 'Departure'

        if (isArrival) {
          // Asigna el valor de la hora al componente pickupIda/Vuelta segun sea el caso
          this.editedItem[`pickup_${tipo}`] = time
          // establece la zona horaria a la fecha
          const fechaHora = moment.tz(from, this.timeZone)
          // Guarda en el store la fecha-hora del pickup
          this.setPickupHora(fechaHora, tipo)
        } else {
          this.editedItem[`pickup_${tipo}`] = await this.obtenerPickup(
            date,
            time,
            this.timeZone,
            tipo
          )
        }

        this.obtenerVuelosChafa(from, to, direction, tipo)
      } else {
        this.editedItem[`pickup_${tipo}`] = time
        if (tipo === 'ida') {
          const fechaHora = moment.tz(from, this.timeZone)
          this.setPickupHora(fechaHora, tipo)
        }
      }
    },

    /**
     * Método asincrónico que se dispara cuando cambia el filtro de fechas.
     * Activa una superposición, borra la lista de traslados y luego la actualiza.
     *
     * @async
     * @returns {void} No retorna nada.
     *
     * @example
     * // Si el filtro de fecha cambia, este método actualizará la lista de traslados.
     * onFilterOutdateChange()
     */
    async onFilterOutdateChange() {
      this.overlay = true
      this.traslados = []
      this.obtenerTraslados()
      this.overlay = false
    },

    /**
     * Método que se dispara cuando cambia el hotel seleccionado. Actualiza la propiedad 'changed' a verdadero,
     * y configura la zona, así como las fechas de recogida para la ida y la vuelta según el hotel seleccionado.
     * Las mutaciones se hacen en el almacén Vuex usando los métodos mapeados 'setZona', 'setPickupIda' y 'setPickupVuelta'.
     *
     * @returns {void} No retorna ningún valor.
     *
     * @example
     * // Si el hotel seleccionado cambia, el método actualiza la zona en el almacén Vuex y pone a null las fechas de recogida para ida y vuelta.
     * onHotelChange()
     */
    onHotelChange() {
      this.changed = true
      const destino = this.hoteles.find((hotel) => hotel._id === this.editedItem.hotel)

      this.setZona(destino.direccion.zona._id)
      this.setTickets(this.haveTickets)
      this.setPickupIda(null)
      this.setPickupVuelta(null)
    },

    /**
     * Método que se dispara cuando se selecciona un segundo hotel en la interfaz de usuario.
     * Establece la segunda zona en el almacén Vuex y actualiza la disponibilidad de los tickets.
     *
     * @returns {void} No retorna ningún valor.
     *
     * @example
     * // Si el segundo hotel es seleccionado o cambia, el método actualiza la zona en el almacén Vuex y pone a null las fechas de recogida para ida y vuelta.
     * onHotel2Change()
     */
    onHotel2Change() {
      this.changed = true
      const destino = this.hoteles.find((hotel) => hotel._id === this.editedItem.hotel2)

      this.setZona2(destino.direccion.zona._id)
      this.setTickets(this.haveTickets)
      this.setPickupIda(null)
      this.setPickupVuelta(null)
    },

    /**
     * Actualiza la hora de recogida (pickup) basada en el traslado seleccionado.
     *
     * Esta función obtiene la fecha y hora del traslado ('ida' o 'vuelta'), y determina
     * si la hora de recogida es del día anterior. Si es así, ajusta la fecha de recogida
     * al día anterior. Luego, devuelve la fecha y la hora de recogida en formato ISO.
     *
     * @param {string} traslado - El tipo de traslado, puede ser 'ida' o 'vuelta'.
     *
     * @example
     * // Supongamos que this.editedItem está definido de la siguiente manera:
     * this.editedItem = {
     *   fecha_ida: '2023-08-15',
     *   hora_ida: '08:00',
     *   pickup_ida: '07:00',
     *   fecha_vuelta: '2023-08-20',
     *   hora_vuelta: '16:00',
     *   pickup_vuelta: '14:00'
     * }
     * // this.timeZone es 'America/Mexico_City'
     *
     * this.onPickupChange('ida')
     * // El resultado de la operación será que this.setPickupHora se llamará con
     * // la fecha y hora de recogida corregida ('2023-08-14T07:00') para el traslado de ida
     *
     * this.onPickupChange('vuelta')
     * // El resultado de la operación será que this.setPickupHora se llamará con
     * // la fecha y hora de recogida ('2023-08-20T14:00') para el traslado de vuelta
     */
    onPickupChange(traslado) {
      let fecha = null
      let hora = null
      let pickup = null
      if (traslado === 'ida') {
        if (this.editedItem.fecha_ida !== null && this.editedItem.hora_ida !== null) {
          fecha = this.editedItem.fecha_ida
          hora = this.editedItem.hora_ida
          pickup = this.editedItem.pickup_ida
        }
      } else {
        if (this.editedItem.fecha_vuelta !== null && this.editedItem.hora_vuelta !== null) {
          fecha = this.editedItem.fecha_vuelta
          hora = this.editedItem.hora_vuelta
          pickup = this.editedItem.pickup_vuelta
        }
      }

      // Determina si la hora de pickup es del dia anterior
      const [horaH, horaM] = hora.split(':').map(Number)
      const [pickupH, pickupM] = pickup.split(':').map(Number)
      const totalMinutosHora = horaH * 60 + horaM
      const totalMinutosPickup = pickupH * 60 + pickupM

      if (totalMinutosPickup > totalMinutosHora) {
        const fechaObjeto = new Date(fecha)
        fechaObjeto.setDate(fechaObjeto.getDate() - 1)
        fecha = fechaObjeto.toISOString().split('T')[0]
      }

      // Devuelve la fecha y la hora de recogida en formato ISO
      const pickupTime = moment.tz(`${fecha}T${pickup}`, this.timeZone)
      this.setPickupHora(pickupTime, traslado)
    },

    /**
     * Ordena el array de objetos según el campo createdAt en orden ascendente o descendente.
     * @example
     * // Ascendente
     * this.sortArray()
     * // Descendente
     * this.isAscending = false;
     * this.sortArray()
     */
    onOrderChange() {
      return this.isAscending
        ? this.traslados.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        : this.traslados.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },

    /**
     * @description Obtiene el tipo de traslado de acuerdo al traslado seleccionado
     * y marca que ha cambiado el valor del un campo, tambien limpia toda la
     * informacióin referente a vuelos
     */
    onTipoTrasladoChange() {
      this.changed = true
      // Verifica si el traslado es sencillo o redondo
      const tipo_traslado = this.tiposTraslado.find(
        (traslado) => traslado.traslado === this.editedItem.clave_traslado
      )
      // TODO: Revisar manejo de errores
      this.editedItem.servicios[0].tipo_traslado = tipo_traslado.tipo
      this.setTipoTraslado(tipo_traslado.tipo)

      // Limpia la informacion referente a los vuelos
      this.limpiaInfoVuelos()

      // Actualiza etiquetas
      this.updateLabels(tipo_traslado)
      // Reinicia campos relacionados con el tipo de traslado

      this.editedItem.hotel = ''
      this.$v.editedItem.hotel.$reset()
      this.editedItem.pickup_ida = ''
      this.editedItem.hotel2 = ''
      this.$v.editedItem.hotel2.$reset()
      this.editedItem.pickup_vuelta = ''
      this.setZona('')
      this.setZona2('')
    },

    openDialogPDF(item) {
      this.selectedItem = item
      this.dialogPDF = true
    },

    /**
     * @description Guarda el contenido del formulario de wizard de reservas
     *
     * @param {Object} body - Objeto con todos los datos de la reserva
     */
    save(body) {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('transferReservation/patchTransferBooking', payload)
            .then((response) => {
              Object.assign(this.traslados[this.editedIndex], response.data)
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.close()
              this.overlay = false
            })
            .catch((err) => {
              console.log(err)
              this.$store.commit('ALERT', {
                color: 'error',
                text: 'err.response.data.errores[0].msg',
                // Enviar a inicio de sesión
              })
              this.close()
              this.overlay = false
            })
        } else {
          this.$store
            .dispatch('transferReservation/addTransferReservation', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.traslados.push(response.data)
              this.close()
              this.overlay = false
            })
            .catch((err) => {
              console.log(err)
              this.$store.commit('ALERT', {
                color: 'error',
                text: err,
                // Enviar a inicio de sesión
              })
              this.close()
              this.overlay = false
            })
        }
      }
    },

    /**
     * @description Envia la reserva por correo electronico al cliente
     */
    sendMail(item) {
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea enviar el correo de nuevo?')) {
        this.overlay = true
        let payload = { id: this.editedItem._id }
        this.$store
          .dispatch('transferReservation/sendMailTansferReservation', payload)
          .then(() => {
            this.$store.commit('ALERT', {
              color: 'success',
              text: 'El correo se envió con éxito',
            })
            this.overlay = false
          })
          .catch((err) => {
            this.$store.commit('ALERT', {
              color: 'error',
              text: err.response.data.msg,
            })
            this.overlay = false
          })
      }
    },

    /**
     * @method setEditedItem
     * @description Este método establece los detalles del item editado.
     * @param {Object} item El objeto de datos que contiene la información a ser editada.
     *
     */
    setEditedItem(item) {
      // Obtiene los valores de las claves del primer nivel de anidamiento
      this.assignFirstLevelValues(item, this.editedItem)

      // Obtiene los valores de las claves del segundo nivel de anidamiento
      this.assignSecondLevelValues(item, this.editedItem)

      // Obtiene los valores de los boletos en caso de existir
      this.processTickets(item.servicios[0])

      // Obtiene los valores de los traslados
      let direction = this.getDirectionAndHotel(
        item.servicios[0].traslados[0],
        this.editedItem.clave_traslado
      )

      // Obtiene los datos de la fecha y horas del traslado
      this.assignTraslados(item.servicios[0], direction)

      // Obtiene los datos del pago y tipo de pago
      this.editedItem.pago = item.pago // anidamiento 2
      let pago = this.payments.find((payment) => payment.type === item.pago.tipo)
      this.editedItem.payment = pago.id
    },

    /**
     * Guarda la fecha y la hora en el store.
     *
     * @param {string} fechaHora - La fecha y la hora en formato 'YYYY-MM-DDTHH:mm'.
     * @param {string} traslado - El tipo de traslado, 'ida' o 'vuelta'.
     *
     * @example
     * // Guarda '2023-05-22T18:30' para el traslado de ida.
     * setPickupHora('2023-05-22T18:30', 'ida');
     */
    setPickupHora(fechaHora, traslado) {
      console.log('entro en setPickupHora')
      const formattedFechaHora = moment(fechaHora).toISOString()
      traslado === 'ida'
        ? this.setPickupIda(formattedFechaHora)
        : this.setPickupVuelta(formattedFechaHora)
    },

    /**
     * @description muestra el JSON de la reserva seleccionada
     */
    showJSON(item) {
      this.selectedItem = item
      this.dialogJSON = true
    },

    /**
     * Actualiza las etiquetas de los inputs dependiendo si el traslado incluye o no el aeropuerto
     * como origen o destino
     * @param {Object} tipo_traslado - Objeto con el tipo de traslado
     *
     * @example
     * this.updateLabels({traslado: 'hotel-apto', tipo: 'redondo'});
     */
    updateLabels(tipo_traslado) {
      if (tipo_traslado.traslado.includes('apto')) {
        // this.updateAptLabels(tipo_traslado)
        this.labelHoraIdaKey = 'apto'
        const isHotelStart = tipo_traslado.traslado.startsWith('hotel')
        this.updateLabelValues(isHotelStart, 'Ida')

        if (tipo_traslado.tipo === 'redondo') {
          this.updateLabelValues(!isHotelStart, 'Vuelta')
        }
      } else {
        this.labelHoraIdaKey = 'hotel'
        this.labelAerolineaIda = 'Aerolinea*'
        this.labelFechaIda = 'Fecha*'
        this.labelHoraIda = 'Hora*'
        this.labelVueloIda = 'Vuelo*'
        this.labelPickupIda = 'Pickup'
        this.labelAerolineaVuelta = 'Aerolinea'
        this.labelFechaVuelta = 'Fecha*'
        this.labelHoraVuelta = 'Hora*'
        this.labelVueloVuelta = 'Vuelo*'
        this.labelPickupVuelta = 'Pickup'
      }
    },

    /**
     * Actualiza las etiquetas de los inputs dependiendo si el origen es un hotel o
     * el aeropuerto y si es de ida o vuelta
     * @param {boolean} isHotelStart - indica si el traslado inicia con 'hotel'.
     * @param {string} prefix - Prefijo de las etiquetas ('Ida' or 'Vuelta').
     */
    updateLabelValues(isHotelStart, prefix) {
      const labels = isHotelStart
        ? ['Hora Salida', 'Fecha Salida', 'Aerolinea Salida', 'Vuelo Salida', 'Pickup Salida']
        : ['Hora Llegada', 'Fecha Llegada', 'Aerolinea Llegada', 'Vuelo Llegada', 'Pickup Llegada']

      this[`labelHora${prefix}`] = labels[0]
      this[`labelFecha${prefix}`] = labels[1]
      this[`labelAerolinea${prefix}`] = labels[2]
      this[`labelVuelo${prefix}`] = labels[3]
      this[`labelPickup${prefix}`] = labels[4]
    },

    /**
     * Valida si todas las fechas de traslado en los servicios están en el futuro.
     *
     * @param {Object} data - El objeto de datos que contiene servicios y traslados.
     * @returns {boolean} - Devuelve verdadero si todas las fechas de traslado están en el futuro, de lo contrario falso.
     *
     * @example
     * const data = {
     *   servicios: [
     *     {
     *       traslados: [
     *         { fecha: { $date: "2023-10-18T02:20:00.000Z" } },
     *         { fecha: { $date: "2023-10-29T18:05:00.000Z" } }
     *       ]
     *     }
     *   ]
     * };
     * const resultado = validateTransferDate(data);
     * console.log(resultado); // La salida debería ser verdadero o falso
     */
    validateTransferDate(data) {
      const currentDate = moment.tz('America/Cancun')

      return data.servicios.every((servicio) =>
        servicio.traslados.every((traslado) => {
          const rawDate = traslado.fecha.$date || traslado.fecha
          const trasladoDate = moment.tz(rawDate, 'America/Cancun')

          return trasladoDate.isAfter(currentDate)
        })
      )
    },

    ...mapMutations({
      setPickupIda: 'transferReservation/SET_PICKUP_IDA',
      setPickupVuelta: 'transferReservation/SET_PICKUP_VUELTA',
      setTickets: 'transferReservation/SET_TICKETS',
      setTipoTraslado: 'transferReservation/SET_TIPO_TRASLADO',
      setZona: 'transferReservation/SET_ZONA',
      setZona2: 'transferReservation/SET_ZONA2',
    }),
  },
}
</script>

<style lang="sass">
.v-card.v-card.v-card--account
  border-color: currentColor
  border-width: 4px

  .v-icon
    color: inherit

.v-card--account,
.v-card--account:before
  border-radius: 50%

.v-card--reveal
  bottom: 0
  opacity: 1 !important
  position: absolute
  width: 100%
</style>
